import { PermissionDto } from "./permission.model";

export class UserDto {
    companyLogo!: string;
    companySlug!: string;
    id!: number;
    permissions!: PermissionDto[];
    privilege!: string;
    token!: string;
    userEmail!: string;
    userImage!: string;
    userName!: string;
    companyPin!: string;
}