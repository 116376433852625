import { OrdersDto } from "src/app/shared/models/orders.model";
import { ServiceDto } from "src/app/shared/models/service.model";

export class TechnicianDto {
    email!: string;
    id!: number;
    mobile!: string;
    name!: string;
    nationality!: NationalityDto;
    cities!: any[];
    image!: string;
    orders!: OrdersDto[];
    services!: ServicesDto[];
    technician_contract: any;
    active!: any;
    appConnection!: any;
    create_at!: any;
    image_pin!: string;
    holidays!:any
}

export class NationalityDto {
    id!: number;
    name!: string;
}

export class TechnicianParamsDto {
    per_page!: number;
    page!: number;
    name!: string;
    active!: any;
    contract_type!: any;
}

export class ServicesDto {
    id!: number;
    name!: string;
    icon!: any;
}

export class ContractTypeDto {
    id!: number;
    name!: string;
}

export class TechnicianCreateDto {
    name!: string;
    active!: number;
    mobile!: string;
    email!: string;
    city_ids!: number[];
    service_ids!: number[];
    password!: string;
    nationality_id!: number;
    contract_type!: number;
    image!: string;
    image_pin!: string;
}
