<div class="overlay" id="overLay" *ngIf="overLayShow" (click)="sidebarOverlayTrigger()"></div>
<div class="page_main_blocks side_page" *ngIf="showSideMenu" [@fade]>
  <div class="logo">
    <img [src]="
        user.companyLogo
          ? user.companyLogo
          : '../../../assets/img/white_logo1.png'
      " alt="" />
  </div>
  <div class="menu">
    <ul>
      <li>
        <a [routerLink]="['/home']" routerLinkActive="active">
          <i class="fas fa-home"></i>
          لوحة التحكم
        </a>
      </li>
      <li appPermission [type]="'all'" [alternativeType]="'create'" [alternativeModule]="'waiting_orders'"
        [module]="'orders'" *ngIf="grantAll || orders" class="drop_list" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('orders_menu')">
          <i class="fas fa-th-list"> </i>
          الطلبات
        </a>
        <ul class="drop_menu" id="orders_menu">
          <li appPermission [type]="'create'" [alternativeModule]="'waiting_orders'" [module]="'orders'">

            <a routerLink="/orders/add-order" routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إضافة عمل
            </a>
          </li>
          <li appPermission [type]="'all'" [alternativeModule]="'waiting_orders'" [module]="'orders'"
            *ngIf="grantAll || order_all">
            <a routerLink="/orders/all-orders" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              كل الطلبات
            </a>
          </li>
          <li appPermission [type]="'all'" [module]="'orders'" *ngIf="grantAll || order_all">
            <a routerLink="/orders/orders-table" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              جدول الطلبات
            </a>
          </li>
          <li appPermission [type]="'all'" [module]="'orders'" *ngIf="grantAll || order_all">
            <a routerLink="/orders/orders-map" routerLinkActive="active">
              <i class="fas fa-map-marker-alt"> </i>
              خريطة الطلبات
            </a>
          </li>
        </ul>
      </li>

      <li appPermission [type]="'all'" [alternativeType]="'create'" [module]="'technicians'"
        *ngIf="grantAll || technicians" class="drop_list" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('technicians_menu')">
          <i class="fas fa-users-cog"> </i>
          مقدم الخدمة
        </a>
        <ul class="drop_menu" id="technicians_menu">
          <li appPermission [type]="'create'" [module]="'technicians'" *ngIf="grantAll || technician_add">
            <a routerLink="/technicians/add-technical" routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إضافة مقدم الخدمة
            </a>
          </li>
          <li appPermission [type]="'all'" [module]="'technicians'" *ngIf="grantAll || technician_all">
            <a routerLink="/technicians/all-technicians" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              كل مقدم الخدمة
            </a>
          </li>
          <li appPermission [type]="'all'" [module]="'technicians'" *ngIf="grantAll || technician_all">
            <a routerLink="/technicians/all-schedules" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              الدوامات
            </a>
          </li>
        </ul>
      </li>
      <li appPermission [type]="'all'" [module]="'resources'" [alternativeType]="'create'"
        *ngIf="grantAll || resoureces" class="drop_list" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('resources_menu')">
          <i class="fas fa-user-friends"> </i>
          المصادر
        </a>
        <ul class="drop_menu" id="resources_menu">
          <li appPermission [type]="'create'" [module]="'resources'" *ngIf="grantAll || resource_add">
            <a routerLink="/resources/add-resource" routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إضافة مصدر
            </a>
          </li>
          <li appPermission [type]="'all'" [module]="'resources'" *ngIf="grantAll || resources_all">
            <a routerLink="/resources/all-resources" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              كل المصادر
            </a>
          </li>
        </ul>
      </li>
      <li appPermission [type]="'all'" [alternativeType]="'create'" [module]="'clients'" *ngIf="grantAll || clients"
        class="drop_list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('clients_menu')">
          <i class="fas fa-users"> </i>
          العملاء
        </a>
        <ul class="drop_menu" id="clients_menu">
          <li appPermission [type]="'create'" [module]="'clients'" *ngIf="grantAll || client_add">
            <a routerLink="/clients/add-client" routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إضافة عميل
            </a>
          </li>
          <li appPermission [type]="'all'" [module]="'clients'" *ngIf="grantAll || client_all">
            <a routerLink="/clients/all-clients" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              كل العملاء
            </a>
          </li>
        </ul>
      </li>

      <li appPermission [type]="'all'" [alternativeType]="'create'" [module]="'offers'" *ngIf="grantAll || offers"
        class="drop_list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('offers_menu')">
          <i class="fas fa-gift"> </i>
          العروض
        </a>
        <ul class="drop_menu" id="offers_menu">
          <li appPermission [type]="'create'" [module]="'offers'" *ngIf="grantAll || offer_add">
            <a routerLink="/offers/add-offer" routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إضافة عرض
            </a>
          </li>
          <li appPermission [type]="'all'" [module]="'offers'" *ngIf="grantAll || offer_all">
            <a routerLink="/offers/all-offers" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              كل العروض
            </a>
          </li>
        </ul>
      </li>
      <li appPermission [module]="'receipts'" [alternativeType]="'create'" [type]="'all'" *ngIf="grantAll || receipts"
        class="drop_list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('invoices_menu')">
          <i class="fas fa-file-invoice"> </i>
          الفواتير
        </a>
        <ul class="drop_menu" id="invoices_menu">
          <li appPermission [type]="'create'" [module]="'receipts'">
            <a style="font-size: 13px !important;" [routerLink]="['/clients/invoice-clients','client']"
              routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إصدار فاتورة لعميل
            </a>
          </li>
          <li appPermission [type]="'create'" [module]="'receipts'">
            <a style="font-size: 13px !important;" [routerLink]="['/clients/invoice-clients','company']"
              routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إصدار فاتورة لشركة
            </a>
          </li>
          <li appPermission class="drop_menu" [type]="'create'" [module]="'receipts'">
            <a style="font-size: 13px !important;" routerLink="/orders/invoice-orders" routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إصدار فاتورة لطلب
            </a>
          </li>
          <li appPermission [module]="'receipts'" [type]="'all'">
            <a style="font-size: 13px !important;" routerLink="/invoices/all-invoices" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              جميع الفواتير
            </a>
          </li>
        </ul>
      </li>
      <li appPermission [module]="'client_companies'" class="drop_list" [alternativeType]="'create'" [type]="'all'"
        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('client-company')">
          <i class="fas fa-building"></i>
          الشركات
        </a>
        <ul class="drop_menu" id="client-company">
          <li appPermission [module]="'client_companies'" [type]="'create'">
            <a routerLink="/client-company/add-client-company" routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إضافة شركة
            </a>
          </li>
          <li appPermission [module]="'client_companies'" [type]="'all'">
            <a routerLink="/client-company/list-client-company" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              كل الشركات
            </a>
          </li>
          <li appPermission [module]="'client_companies'" [type]="'all'">
            <a routerLink="/client-company/client-companies-map" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              خريطة الشركات
            </a>
          </li>
        </ul>
      </li>


      <li *ngIf="superAdmin" class="drop_list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('contracts-menu')">
          <i class="fas fa-file-signature"></i>
          العقود
        </a>
        <ul class="drop_menu" id="contracts-menu">
          <li>
            <a routerLink="/contracts/add-contract" routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إضافة عقد
            </a>
          </li>
          <li>
            <a routerLink="/contracts/all-contracts" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              كل العقود
            </a>
          </li>
        </ul>

      </li>
      <li class="drop_list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('supplier-menu')">
          <i class="fas fa-truck"></i>
          الموردين
        </a>
        <ul class="drop_menu" id="supplier-menu">
          <li>
            <a routerLink="/suppliers/add-supplier" routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              إضافة مورد
            </a>
          </li>
          <li>
            <a routerLink="/suppliers/all-suppliers" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              كل الموردين
            </a>
          </li>
        </ul>

      </li>

      <!-- purchases -->

      <!-- <li class="drop_list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('purchase-menu')">
          <i class="fas fa-truck"></i>
          المشتريات
        </a>
        <ul class="drop_menu" id="purchase-menu">

          <li>
            <a style="padding:5px 3rem !important" routerLink="/purchases/all-purchases" routerLinkActive="active">
              <i style="font-size: 11px !important;" class="fas fa-th-list"> </i>
              جدول المشتريات
            </a>
          </li>
          <li>
            <a style="padding:5px 3rem !important" routerLink="/purchases/add-purchase-order" routerLinkActive="active">
              <i style="font-size: 11px !important;" class="fas fa-plus"> </i>
              امر شراء جديد
            </a>
          </li>
          <li>
            <a style="padding:5px 3rem !important" routerLink="/purchases/add-category" routerLinkActive="active">
              <i style="font-size: 11px !important;" class="fas fa-th-list"> </i>
              تصنيف رئيسي
            </a>
          </li>
          <li>
            <a style="padding:5px 3rem !important" routerLink="/purchases/add-sub-category" routerLinkActive="active">
              <i style="font-size: 11px !important;" class="fas fa-th-list"> </i>
              تصنيف فرعي
            </a>
          </li>
          <li>
            <a style="padding:5px 3rem !important" routerLink="/purchases/add-product" routerLinkActive="active">
              <i style="font-size: 11px !important;" class="fas fa-th-list"> </i>
              المنتجات
            </a>
          </li>
        </ul>

      </li> -->



      <!-- <li *ngIf="grantAll || service_all" class="drop_list" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <a (click)="toggle_drop_List('services_menu')">
          <i class="fas fa-tools"></i>
          الخدمات
        </a>
        <ul class="drop_menu" id="services_menu">
          <li *ngIf="grantAll || service_all">
            <a routerLink="/company-services/all-main-services" routerLinkActive="active">
              <i class="fas fa-th-list"> </i>
              خدمات رئيسية
            </a>
          </li>

            <a
              routerLink="/company-services/all-sub-services"
              routerLinkActive="active"
            >
              <i class="fas fa-th-list"> </i>
              خدمات فرعية
            </a>
          </li> 
       </ul>
      </li> -->
      <li class="drop_list" *ngIf="grantAll" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        routerLink="/managment">
        <a>
          <i class="fas fa-user-shield"></i>
          الادارة
        </a>
      </li>
      <!-- <li class="drop_list" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="grantAll || sales_all">
        <a (click)="toggle_drop_List('sales')">
          <i class="fas fa-sort-shapes-down-alt"></i>
          المبيعات
        </a>
        <ul class="drop_menu" id="sales">
          <li *ngIf="grantAll || sales_add">
            <a routerLink="/sales/add-sale" routerLinkActive="active">
              <i class="fas fa-plus"> </i>
              اضافة مبيعات جديدة
            </a>
          </li>
          <li *ngIf="grantAll || sales_all">
            <a routerLink="/sales/all-sales" routerLinkActive="active">
              <i class="fas fa-table"> </i>
              جدول المبيعات
            </a>
          </li>
          <li *ngIf="grantAll || sales_all">
            <a routerLink="/sales/orders-sales-map" routerLinkActive="active">
              <i class="fas fa-map-marked-alt"> </i>
              خريطة المبيعات
            </a>
          </li>
          <li>
            <a routerLink="/sales/draft-sales" routerLinkActive="active">
              <i class="fas fa-comment-alt-plus"> </i>
              اعمال التطبيق
            </a>
          </li>
        </ul>
      </li> -->
    </ul>
  </div>
</div>