// angular modules
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

// angular material
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';


// components
import { SideMenuMgtComponent } from "./components/side-menu-mgt/side-menu-mgt.component";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { SideMenuOwnerComponent } from "./components/side-menu-owner/side-menu-owner.component";
import { HeaderComponent } from "./components/header/header.component";
import { HeaderOwnerComponent } from "./components/header-owner/header-owner.component";
import { AlertsComponent } from "./components/alerts/alerts.component";
import { MultiselectFilterComponent } from "./components/multiselect-filter/multiselect-filter.component";
import { MapComponent } from "./components/map/map.component";
import { FooterComponent } from "./components/footer/footer.component";
// import { LoaderComponent } from './components/loader/loader.component';

// interceptors
import { ApiInterceptor } from "./interceptors/api.interceptor";
import { LoaderInterceptor } from './interceptors/loader.interceptor';

// services
import { LoaderService } from "../shared/services/loader.service";
import { ResponseStateService } from "../shared/services/response-state.service";


// map
import { AgmCoreModule } from "@agm/core";
import { AgmOverlays } from "agm-overlays";
import { AgmDirectionModule } from 'agm-direction';   // agm-directionimport { AgmDrawingModule } from '@agm/drawing';


import { AgmDrawingModule } from '@agm/drawing';

import * as io from 'socket.io-client';


// ngx 
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatTabsModule } from "@angular/material/tabs";
// import { ColorPickerModule } from 'ngx-color-picker';

// primeng
import { ColorPickerModule } from "primeng/colorpicker";
import { loadingService } from "./services/loading.service";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { permissionDirective } from './directives/permission.directive';
import { OrderPermissionsDirective } from './directives/order-permissions.directive';
import { PasswordDirective } from './directives/password.directive';
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    SideMenuComponent,
    MultiselectFilterComponent,
    SideMenuOwnerComponent,
    SideMenuMgtComponent,
    HeaderComponent,
    HeaderOwnerComponent,
    AlertsComponent,
    MapComponent,
    FooterComponent,
    permissionDirective,
    OrderPermissionsDirective,
    PasswordDirective,
    // LoaderComponent,

  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    MatTabsModule,
    FormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    ColorPickerModule,
    RouterModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMaterialTimepickerModule,
    AgmCoreModule,
    AgmOverlays,
    SideMenuComponent,
    MultiselectFilterComponent,
    SideMenuOwnerComponent,
    SideMenuMgtComponent,
    HeaderComponent,
    HeaderOwnerComponent,
    AlertsComponent,
    MapComponent,
    FooterComponent,
    MatSnackBarModule,
    permissionDirective,
    PasswordDirective
    // LoaderComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    ColorPickerModule,
    ReactiveFormsModule,
    FormsModule,
    MatTabsModule,
    MatSelectModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatAutocompleteModule,
    NgxMaterialTimepickerModule,
    AgmCoreModule,
    AgmOverlays,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyCW4r_HcOqZfIGdz-ZxvFUE1R1AwpTZKBs',
      // apiKey: 'AIzaSyChTEPD15j3gS_5Z7ABhWkghruAxH0mmh0',
      apiKey: "AIzaSyB43NW6QmXMMqA8vv0iFmCKmklFcG4pofs",
      libraries: ["geometry", "places", "drawing"],
      language: "ar",
    }),
    AgmDirectionModule,
    MatSnackBarModule,
    MatTooltipModule,
    AgmDrawingModule


  ],
  providers: [
    LoaderService,
    ResponseStateService,
    DatePipe,
    loadingService,

    // NOTE: interceptors order may cause errors
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },



  ],
})
export class SharedComponentsModule { }
