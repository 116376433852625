// angular modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// models
import { ResponseDto } from '../models/api-response.model';
import { OrdersDto } from '../models/orders.model';
import { StatusUpdateDto } from '../../modules/orders/order.model';

// service
import { SecurityService } from './security.service';
import { Subject } from 'rxjs';
import { AlarmService } from './alarm.service';

@Injectable({
  providedIn: 'root'
})

export class OrdersService {

  noNewOrders = new Subject();
  // #region constructor

  constructor(
    private http: HttpClient,
    private securityService: SecurityService,
    private alarmService: AlarmService,
  ) { }

  // #endregion

  // #region main actions

  getOrders(params = {}) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/orders`;
    return this.http.get<ResponseDto>(url, { params: params });
  }

  getOrdersByTechnicians(params: any = null) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/orders`;
    return params != null ? this.http.get<ResponseDto>(url, { params: params }) : this.http.get<ResponseDto>(url);
  }

  getOrderDetails(id: number, params: any = null) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/orders/${id}`;
    return this.http.get<ResponseDto>(url, { params: params });
  }

  addOrder(body) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/orders`;
    return this.http.post<ResponseDto>(url, body);

  }

  updateOrder(orderId: number, body: any) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/orders/${orderId}`;
    return this.http.put(url, body);
  }

  uploadImg(id: number, body: any) {
    const url = `company/${this.securityService.getUserCompanySlug}/orders/${id}/media`;
    return this.http.post<ResponseDto>(url, body)
  }

  deleteImage(orderId: number, imageId: number) {
    const url = `company/${this.securityService.getUserCompanySlug}/orders/${orderId}/media/${imageId}`;
    return this.http.delete(url);
  }
  cancelOrder(orderId: number, body: any) {
    const url = `company/${this.securityService.getUserCompanySlug}/orders/${orderId}/cancel`;
    return this.http.put(url, body);
  }

  getCounts(params) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/counts`
    return this.http.get(url, params)
  }

  getWaitingOrders(params) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/waiting-orders`
    return this.http.get(url, { params: params })
  }

  // #endregion

  // #region update status

  updateStatus(id: number, body: StatusUpdateDto) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/orders/${id}/update-status`;
    return this.http.put<ResponseDto>(url, body)
  }
  stopbell() {
    let counts = ["orders_not_approved_count"]
    this.getCounts(counts).subscribe((data: any) => {


      if (data) {
        if (data.data.orders_not_approved_count > 0) {
          this.alarmService.unApprovedOrders = true
        } else {
          this.alarmService.unApprovedOrders = false
        }

      }
    });

    // this.alarmService.unApprovedOrders= false
  }

  // #endregion


  addNotes(body) {
    // {{dev}}/api/v1/admin/company/VID/order-notes  
    const url = `admin/company/${this.securityService.getUserCompanySlug}/notes`;
    return this.http.post<ResponseDto>(url, body)
  }

  seeNotes(id: number) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/notes/${id}/seen`;
    return this.http.post<ResponseDto>(url, {})
  }
  deleteNote(id) {
    // {{local}}/api/v1/admin/company/VID/notes/1
    const url = `admin/company/${this.securityService.getUserCompanySlug}/notes/${id}`;
    return this.http.delete(url);
  }

  updateNote(id, body) {
    // {{local}}/api/v1/admin/company/VID/orders/16428
    const url = `admin/company/${this.securityService.getUserCompanySlug}/notes/${id}`;
    return this.http.put<ResponseDto>(url, body)

  }
}
