
import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { ErrorDto } from "../models/api-response.model";
import { AuthService } from "../services/auth.service";
import { ResponseStateService } from "../services/response-state.service";
import { SecurityService } from "../services/security.service";
import { AlertService } from "../services/alert.service";



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private responseStateService: ResponseStateService,
    private securityService: SecurityService,
    private alertService: AlertService,
  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    return next.handle(request)
      .pipe((retry(0)) as any,
        catchError((error: HttpErrorResponse) => {
          const errorModel: ErrorDto = error.error;


          // customized response
          if (error.status === 401 || error.status === 403) {
            this.securityService.logout();
            if (errorModel.status) this.alertService.showWarningMessage(errorModel);
          }
          if (errorModel.status == "failed") {
            // validation errors (more than one)
            if (errorModel.status === "failed") {
              const msg: string = this.handleErrorMessages(errorModel);
              this.alertService.showErrorMessage(msg);
            }

            else if (!errorModel.data.messages) {
              this.alertService.showErrorMessage("Something went wrong please try again or call support");
            }
          }
          //  && error.status !== 0 it was always making that post request and on server displaying an error
          else if (error.status !== 200 && error.status !== 0) {

            this.alertService.showErrorMessage("Something went wrong please try again or call support")

            if (error.status === 401 || error.status === 403) {
              this.securityService.logout();
            }
          }
          // else {

          return throwError(error);
          // }
        }));
  }

  handleErrorMessages(error: ErrorDto): string {
    // debugger
    let msg = "";
    for (const key in error) {
      if (key === 'data') { msg += `${error[key].messages}` }
      // else { msg += `${key.charAt(0).toUpperCase() + key.slice(1)} : ${error[key]} \n`; }
    }
    return msg;
  }
}
