
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { SecurityService } from '../services/security.service';
import { BehaviorSubject } from "rxjs";
import { LoginService } from '../services/login.service';

@Directive({
  selector: '[appPermission]'
})
export class permissionDirective implements OnInit {
  @Input() type: string; //update or create or show 
  @Input() module: string;
  @Input() alternativeModule: string;
  @Input() alternativeType: string;
  @Input() thirdType: string;
  hide: boolean = true;
  constructor(private elementRef: ElementRef, private securityService: SecurityService,private loginService: LoginService) { }

  ngOnInit(): void {
   
  this.loginService.checkPermissions$.subscribe(() => {this.givePermission(); });
  this.givePermission()
  }

  givePermission(){
    const currentUser = this.securityService.retrieveUser();
    const clientPermissions = this.securityService.retrieveUser().permissions?.find(p => p.name == this.module);
    const clientAltPermissions = this.securityService.retrieveUser().permissions?.find(p => p.name == this.alternativeModule);
    
    const clientPrivileges = clientPermissions?.privileges;
    const clientAltPrivileges = clientAltPermissions?.privileges;
    const userPrivilege = currentUser.privilege
    if ((userPrivilege != "super-admin" && userPrivilege !="owner") && !(clientPrivileges?.includes(this.type) || clientPrivileges?.includes(this.alternativeType) || clientPrivileges?.includes(this.thirdType) ||clientAltPrivileges?.includes(this.type) || clientAltPrivileges?.includes(this.alternativeType) || clientAltPrivileges?.includes(this.thirdType))) {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
}
