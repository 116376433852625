import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from "../services/loader.service";
import { environment } from '../../../environments/environment';
import { SecurityService } from '../services/security.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService, private securityService: SecurityService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  afterLoadAllRequests() {
    if (this.requests.length == 0) {
      this.loaderService.handleAfterAllRequestsFunctions();
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // check if user exists in local storage as if no user there's no company slug
    const uniqueUrl = this.securityService.user ? `${environment.baseUrl}admin/company/${this.securityService.getUserCompanySlug}/check-unique` : '';

    // check if url is not checking uniqueness
    if (req.url != uniqueUrl) {
      this.requests.push(req);
      this.loaderService.isLoading.next(true);
    }
    return Observable.create((observer: { next: (arg0: HttpResponse<any>) => void; error: (arg0: any) => void; complete: () => void; }) => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            this.afterLoadAllRequests();
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
