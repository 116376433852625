// angular modules
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

// environment
import { environment } from '../../../environments/environment';

// rxjs
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.startsWith('http') && !request.url.startsWith('https') && !request.url.includes('assets')) {
      const baseUrl = environment.baseUrl;
      const apiRequest = request.clone({ url: `${baseUrl}${request.url}` });

      return next.handle(apiRequest);
    } else {
      return next.handle(request);
    }

  }
}
