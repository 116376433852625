// angular modules
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// services
import { SecurityService } from './security.service';
import { WebSocketService } from './web-socket.service';

@Injectable()
export class AuthGuard implements CanActivate {


  constructor(public router: Router, private readonly secuirtyService: SecurityService) { }

  // activate if user has valid token
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.secuirtyService.user) {
      this.secuirtyService.logout();
      return false;
    } else {
      return true;
    }
  }



  // FIXME: OLD CODE
  //  currentUser ;
  // /* --------------- Constructor -------------------- */
  // constructor(private router: Router , private WebSocketService : WebSocketService  ) {
  //   this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
  // }

  // canActivate()
  // {
  //   if ( localStorage.getItem('currentUser'))
  //   {
  //     // this.router.navigate(['/']);
  //     return true
  //   }
  //   else if(!localStorage.getItem('currentUser')  )
  //     {
  //       this.router.navigate(['access/login']);
  //       return false;
  //     }
  // }



}
