import { Injectable } from "@angular/core";
import Echo from 'laravel-echo'
import { SecurityService } from "./security.service";
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";


@Injectable()
export class WebSocketService {

  // #region declare variables

  echo: any;
  websocketEventSubject$: BehaviorSubject<any>;
  websocketEventsList: any[] = [];

  technicianCurrentLocationSubject$: BehaviorSubject<any>;
  technicianCurrentLocationList: any[] = []

  technicianLogOutSubject$: BehaviorSubject<boolean>;
  isTechnicianLoggedOut: boolean;
  technicianMobileData$: BehaviorSubject<any>;
  mobileData: any
  techStatus$: BehaviorSubject<boolean>
  techOnline: boolean

  // #endregion

  // #region constructor

  constructor(private securityService: SecurityService) {
    this.websocketEventSubject$ = new BehaviorSubject<any>(this.websocketEventsList);
    this.technicianCurrentLocationSubject$ = new BehaviorSubject<any>(this.technicianCurrentLocationList);

    this.isTechnicianLoggedOut = false;
    this.technicianLogOutSubject$ = new BehaviorSubject<boolean>(this.isTechnicianLoggedOut);
    this.technicianMobileData$ = new BehaviorSubject<any>(this.mobileData)
    this.techStatus$ = new BehaviorSubject<boolean>(this.techOnline)
  }

  // #endregion

  // #region connect to server

  connectionConfig() {
    this.echo = new Echo({
      broadcaster: environment.webSocket.BROADCASTER,
      key: environment.webSocket.KEY,
      cluster: environment.webSocket.CLUSTER,
      wsHost: environment.webSocket.WSHOST,
      wssPort: environment.webSocket.WSSPORT,
      forceTLS: true,
      authEndpoint: `${environment.baseUrl}broadcasting/auth`, // needs to be specified to not redirect to frontend server 
      auth: {
        headers: {
          authorization: `Bearer ${this.securityService.jwtToken}`
        }
      }
    });
  }


  // #endregion

  // #region order events

  // order created 
  listenCreatedOrderEvent() {
    let companySlug = this.securityService.getUserCompanySlug;
    this.echo.private(`${companySlug}.orders.show`).listen('.created', (e: object) => {
      e['type'] = 'created';
      this.appendNewEvent(e, 'notification');
    });
  }

  // order status updated
  listenUpdatedOrderEvent() {
    let companySlug = this.securityService.getUserCompanySlug;
    this.echo.private(`${companySlug}.orders.show`).listen('.status', (e: object) => {
      console.log('websocket slug event => ', e);

      e['type'] = 'updated';
      this.appendNewEvent(e, 'notification');
    });
  }

  // user permissions updated 
  listenPermissionUpdate() {
    let user = this.securityService.retrieveUser();
    let roleId = user.role_id
    let companySlug = this.securityService.getUserCompanySlug;
    this.echo.private(`${companySlug}.permissions.${roleId}.list`).listen('.updated', (e: object) => {
      e['type'] = 'permissionChanges';
      this.appendNewEvent(e, 'notification');
    });

  }

  // append updates in events array to be pushed to the system
  appendNewEvent(event: object, type: string) { // type notification => popup notification, type technician
    if (type === 'notification') {
      this.websocketEventsList.push(event);
      this.websocketEventSubject$.next(this.websocketEventsList)
    } else if (type === 'tech') {
      this.technicianCurrentLocationList.push(event);
      this.technicianCurrentLocationSubject$.next(this.technicianCurrentLocationList);
    }
  }

  // #endregion


  // #region tech events


  // listen on mobile returned value (current location)
  listenTechCurrentLocation(techId: number) {
    // this.connectionConfig();
    this.echo.private(`tech.${techId}.track`).listen('.mobile-current-location', (e: any) => {
      e['tech_id'] = techId;
      this.appendNewEvent(e, 'tech');
    });
  }

  checkTechnicianLogOut(techId: number) {
    this.echo.private(`tech.${techId}.track`).listen('.mobile-logout', () => {
      this.isTechnicianLoggedOut = true;
      this.technicianLogOutSubject$.next(this.isTechnicianLoggedOut);
    });
  }
  //todo:change as of request response
  getTechnicianAppData(techId: number) {
    console.log('hello')
    this.echo.private(`tech.${techId}.track`).listen('.mobile-initial-data', (e: any) => {
      console.log(e, "eeeeeee")
      // e['tech_id'] = techId;
      this.appendNewEvent(e, 'tech');

      this.mobileData = e
      this.technicianMobileData$.next(this.mobileData);
    });
  }
  checkTechOnline(techId: number) {
    this.echo.private(`tech.${techId}.track`).listen('.mobile-online', (e: any) => {
      console.log(e, "online")
      // e['tech_id'] = techId;
      this.appendNewEvent(e, 'tech');

      this.techOnline = true
      this.techStatus$.next(this.techOnline);
    });
  }
  checkTechOffline(techId: number) {
    this.echo.private(`tech.${techId}.track`).listen('.mobile-offline', (e: any) => {
      console.log(e, "offline")
      // e['tech_id'] = techId;
      this.appendNewEvent(e, 'tech');

      this.techOnline = false
      this.techStatus$.next(this.techOnline);
    });
  }
  // #endregion 

  // #region order subscribe

  techSubscribe(techId: number) {
    this.connectionConfig();
    this.listenTechCurrentLocation(techId);
    this.checkTechnicianLogOut(techId);
  }

  subscribe() {
    console.log('subscribe');

    //fixme i stopped to pass pipelines
    this.connectionConfig();
    this.listenCreatedOrderEvent();
    this.listenUpdatedOrderEvent();
    this.listenPermissionUpdate();
  }

  // #endregion


}
