import { Injectable } from '@angular/core';
import { Howl, Howler } from 'howler';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AlarmService {
  unApprovedOrders: boolean = false;
  animatedBell = new BehaviorSubject(this.unApprovedOrders);
  alarmObservable = new BehaviorSubject(false);
  sound: any;

  constructor(
  ) {
    // show/hide animated bell
    this.animatedBell.subscribe((result) => {
      setTimeout(() => { this.unApprovedOrders = result; }, 0.001);
  
    });

    // initiate sound obj
    this.sound = new Howl({
      src: ['assets/sounds/mixkit-alarm-digital-clock-beep-989.wav'],
      loop: true,
    });

  }
  play() {
    this.sound.play();

  }

  stopAudio() {
    // this.playPromise.pause()
    this.sound.stop()
  }

  stopAlarm(value: any) {
 

    this.alarmObservable.next(value)
  }

}
