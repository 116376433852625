import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SecurityService } from './security.service';
@Injectable()
export class LoginGuard implements CanActivate {

  // #region constructor

  constructor(
    private router: Router,
    private securityService: SecurityService
  ) { }

  // #endregion

  // #region canActivate

  canActivate() {
    const user = this.securityService.retrieveUser();
    if (user && user.token) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  // #endregion
}
