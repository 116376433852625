import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./shared/services/auth.guard.service";
import { LoginGuard } from "./shared/services/login.guard.service";

const routes: Routes = [
  {
    path: "",
    // FIXME: it gives an error as it falls back to home and it gives an authentication error if not logged in
    canActivate: [AuthGuard],
    children: [
      { path: "", pathMatch: "full", redirectTo: "home" },
      // {
      //   path: 'system-managment', component: SystemManagmentComponent, canActivate: [AuthGuard]
      // },
      {
        path: "system-managment",
        loadChildren:
          () => import('./modules/system-managment/system-managment.module').then(m => m.SystemManagmentModule),
      },
      {
        path: "system-off",
        loadChildren:
          () => import('./modules/system-off/system-off.module').then(m => m.SystemOffModule),
      },

      // {
      //   path: 'system-off', component: SystemOffComponent, canActivate: [AuthGuard]
      // },

      // { path: 'access', loadChildren: './templates/access/access.module#AccessModule' },
      {
        path: "orders",
        loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule),
      },
      {
        path: "sales",
        loadChildren: () => import('./modules/sales/sales.module').then(m => m.SalesModule),
      },
      {
        path: "clients",
        loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule),
      },
      {
        path: "password",
        loadChildren: () => import('./modules/password/password.module').then(m => m.PasswordModule),
      },
      {
        path: "invoices",
        loadChildren: () => import('./modules/invoices/invoices.module').then(m => m.InvoicesModule),
      },
      {
        path: "technicians",
        loadChildren:
          () => import('./modules/technicians/technicians.module').then(m => m.TechniciansModule),
      },
      {
        path: "resources",
        loadChildren: () => import('./modules/resources/resources.module').then(m => m.ResourcesModule),
      },
      {
        path: "offers",
        loadChildren: () => import('./modules/offers/offers.module').then(m => m.OffersModule),
      },
      {
        path: "services",
        loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule),
      },
      {
        path: "company-services",
        loadChildren:
          () => import('./modules/company-services/company-services.module').then(m => m.CompanyServicesModule),
      },
      { path: "home", loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
      {
        path: "managment",
        loadChildren: () => import('./modules/managment/managment.module').then(m => m.ManagmentModule),
      },
      {
        path: "owner",
        loadChildren: () => import('./modules/owner/owner.module').then(m => m.OwnerModule),
      },
      {
        path: "receipts-management",
        loadChildren:
          () => import('./modules/receipts-management/receipts-management.module').then(m => m.ReceiptsManagementModule),
      },
      {
        path: "receipts-management/receipts-templates",
        loadChildren:
          () => import('./modules/rec-templates/rec-templates.module').then(m => m.RecTemplatesModule),
      },
      {
        path: "receipts-management/all-models",
        loadChildren:
          () => import('./modules/rec-models/rec-models.module').then(m => m.RecModelsModule),
      },
      {
        path: "receipts-management/all-taxes",
        loadChildren: () => import('./modules/taxes/taxes.module').then(m => m.TaxesModule),
      },
      {
        path: "receipts-management/all-blocks",
        loadChildren: () => import('./modules/block/block.module').then(m => m.BlockModule),
      },
      {
        path: "receipts-management/invoice-settings",
        loadChildren:
          () => import('./modules/invoice-settings/invoice-settings.module').then(m => m.InvoiceSettingsModule),
      },
      {
        path: "receipts-management/charts",
        loadChildren: () => import('./modules/charts/charts.module').then(m => m.ChartsModule),
      },
      {
        path: "receipts-management/reports",
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: "users",
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
      },
      {
        path: "roles",
        loadChildren: () => import('./modules/roles/roles.module').then(m => m.RolesModule),
      },
      {
        path: "companies",
        loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesModule),
      },
      {
        path: "companies-mgt",
        loadChildren:
          () => import('./modules/companies-mgt/companies-mgt.module').then(m => m.CompaniesMgtModule),
      },
      {
        path: "classifications",
        loadChildren:
          () => import('./modules/classifications/classifications.module').then(m => m.ClassificationsModule),
      },
      {
        path: "permissions",
        loadChildren:
          () => import('./modules/permissions/permissions.module').then(m => m.PermissionsModule),
      },
      {
        path: "locations-managment",
        loadChildren:
          () => import('./modules/locations-managment/locations-managment.module').then(m => m.LocationsManagmentModule),
      },
      {
        path: "client-company",
        loadChildren: () => import('./modules/client-company/client-company.module').then(m => m.ClientCompanyModule)
      },
      {
        path: "contracts",
        loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule)
      },
      {
        path: "suppliers",
        loadChildren: () => import('./modules/suppliers/suppliers.module').then(m => m.SuppliersModule)
      },
      {
        path: "purchases",
        loadChildren: () => import('./modules/purchases/purchases.module').then(m => m.PurchasesModule)
      }
    ],
  },
  {
    path: "access",
    canActivate: [LoginGuard],
    loadChildren: () => import('./modules/access/access.module').then(m => m.AccessModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
