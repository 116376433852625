import { Component, OnInit } from "@angular/core";
import { fadeAnimation } from "./animations";
import { MessagingService } from "./shared/services/messaging.service";
import { CoreService } from "./shared/services/core.service";
import { WebSocketService } from "./shared/services/web-socket.service";
import { Router } from "@angular/router";
import { LoaderService } from "./shared/services/loader.service";
import { SecurityService } from "./shared/services/security.service";
import { UserDto } from "./shared/models/user.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit {
  // #region declare variables

  showSideMenu = false;
  currentUser: UserDto;
  documentHeight: any;
  message: any = '';
  e: any;

  // #endregion

  // #region constructor

  constructor(
    private messagingService: MessagingService,
    private coreService: CoreService,
    private WebSocketService: WebSocketService,
    private router: Router,
    public loaderService: LoaderService,
    private securityService: SecurityService
  ) {
    this.currentUser = this.securityService.retrieveUser();

    if (this.currentUser) {
      document.title = this.currentUser.companySlug;
    }

  }

  // #endregion

  // #region ngOnInit

  ngOnInit() {
    const userId = "user001";
    this.messagingService.requestPermission(userId);
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;

    this.getDocumentHEeight();

    if (this.currentUser && this.currentUser.privilege != "owner") {

      this.WebSocketService.subscribe();
      // this.WebSocketService.techSubscribe();
    }


  }

  // #endregion


  // # region calculate height

  getDocumentHEeight() {
    let docHeight = document.body.scrollHeight + window.screen.height;
    this.documentHeight = `height: ${docHeight}px`;
  }

  // #endregion
}
