// angular modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// models
import { ResponseDto } from '../models/response.model';

// services
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // #region constructor

  constructor(
    private securityService: SecurityService,
    private http: HttpClient
  ) { }

  // #endregion

  // #region complementary requests

  getCities() {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/cities`;
    return this.http.get<ResponseDto>(url);
  }
  getOwnerCities(){
    const url = `admin/countries/191/cities`;
    return this.http.get<ResponseDto>(url);
  }
  

  getNationalities() {
    const url = `nationalities`;
    return this.http.get<ResponseDto>(url);
  }

  getServices() {
    const url = `company/${this.securityService.getUserCompanySlug}/services`;
    return this.http.get<ResponseDto>(url);
  }

  getContractTypes() {
    const params: any = { 'types[]': 'technician_contract' }
    const url = `lookup`;
    return this.http.get<ResponseDto>(url, { params: params })
  }

  getGenders() {
    const params: any = { 'types[]': 'gender' }
    const url = `lookup`;
    return this.http.get<ResponseDto>(url, { params: params })
  }

  getCountries() {
    const url = `admin/countries`;
    return this.http.get<ResponseDto>(url)
  }

  getCountryCities() {
    const saudiId = 191;
    const url = `admin/countries/${saudiId}/cities`;
    return this.http.get<ResponseDto>(url)
  }

  getItemTypeSet(params: any) {

    const url = `company/${this.securityService.getUserCompanySlug}/receipts/sets`;
    return this.http.get<ResponseDto>(url, { params: params });
  }

  getSubServices(parentServiceId: number,param:any = {}) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/services/${parentServiceId}`;
    return this.http.get<ResponseDto>(url,param)
  }

  // #endregion

  // #region owner actions

  getOwnerCounts() {
    const url = `admin/counts`;
    return this.http.get<ResponseDto>(url);
  }

  getOwnerClassifications() {
    const url = `admin/classifications`;
    return this.http.get<ResponseDto>(url);
  }

  // #endregion

  // #region check uniqueness

  checkUniqueness(params: any) {
    const url = `company/${this.securityService.getUserCompanySlug}/check-unique`;
    return this.http.get(url, { params: params });
  }

  // #endregion

}
