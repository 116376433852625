// angular modules
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// rxjs
import { Subject } from 'rxjs';

// services
import { CoreService } from './core.service';
import { HeadersService } from './../../shared/services/headers.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {

  // #region declare variables

  notificationToken: any = '';
  loginSubject = new Subject();
  loginObservable = this.loginSubject.asObservable();

  // #endregion

  // #region constructor

  constructor(
    private coreService: CoreService,
    private router: Router,
    private headersService: HeadersService,
    private http: HttpClient
  ) { }

  // #endregion

  // #region main actions

  userLogin(data: any) {
    const url = `admin/token`;
    return this.http.post(url, data);
  }

  // login
  login(email: string, password: string) {
    const notificationToken = JSON.parse(localStorage.getItem('notificationToken'));

    this.coreService.postMethod('tkn', { email, password, mobtkn: notificationToken, is_mobile: 0 }).subscribe(
      (user: any) => {
        this.loginSubject.next(user);
        this.headersService.token = user.access_token;
        this.headersService.companySlug = user.company_slug;
        // this.headersService.baseUrl = this.headersService.baseAPI + 'api/company/' + this.headersService.companySlug + '/';
        localStorage.setItem('currentUser', JSON.stringify(user));
        const userData = JSON.parse(localStorage.getItem('currentUser'));
        if (userData.privilege == 'owner' || userData.privilege == 'Owner') {
          setTimeout(() => { this.router.navigate(['/owner']); }, 700);
        } else {
          setTimeout(() => { this.router.navigate(['/']); }, 700);
        }
      },
      error => {
        this.loginSubject.next(error);
      }
    );
  }
  /* ----------------------- Logout ------------------ */
  logout() {
    let user_id = JSON.parse(localStorage.getItem('currentUser')).id;
    const notificationToken = JSON.parse(
      localStorage.getItem('notificationToken')
    );
    this.coreService
      .postMethod('logout', { tkn: notificationToken, id: user_id })
      .subscribe(deleteToken => {
        setTimeout(() => {
          localStorage.removeItem('currentUser');
          localStorage.removeItem('notificationToken');
          location.href = '/access/login';
        }, 500);
      });
  }

  // #endregion
}
