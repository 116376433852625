import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { permissionDirective } from '../directives/permission.directive';
import { LocalStorageService } from './local-storage.service';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  checkPermissions$: BehaviorSubject<any>;
  isNewPermission: boolean;

  constructor(
    private localStorageService: LocalStorageService,
    private securityService: SecurityService,
  ) { 
    this.isNewPermission = false
    this.checkPermissions$ = new BehaviorSubject<any>(this.isNewPermission);
  }

  storeUserData(user: any) {
    if (user.privilege === 'super-admin' || user.privilege === 'owner') {
      this.localStorageService.store('user', {
        token: user.token, companySlug: user.company?.slug, companyLogo: user.company?.logo, userImage: user.user?.image, userName: user.user?.name,
        userEmail: user.user?.email, privilege: user?.privilege, id: user.user?.id, companyPin: user.company?.pin, permissions: user.role?.permissions, role_id:user.role?.id
      });
    } else {
      this.localStorageService.store('user', {
        token: user.token, companySlug: user.company?.slug, companyLogo: user.company?.logo, userImage: user.user?.image, userName: user.user?.name,
        userEmail: user.user?.email, privilege: user?.privilege, permissions: user.role?.permissions, id: user.user?.id, role_id:user.role?.id
      });
    }
  }

  StoreNewPermissions(value){
    console.log(value,"neww valuue per")
    let user = this.securityService.retrieveUser();
    user.permissions = value;
    console.log('user => ', user);
    this.localStorageService.remove('user');
    this.localStorageService.store('user', user);

    this.checkPermissions$.next(this.isNewPermission =  true)
    
    
  }
}
