// angular modules
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';

// services
import { SecurityService } from '../services/security.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  // #region constructor

  constructor(
    private securityService: SecurityService
  ) { }

  // #endregion

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add token to request header
    // !request.url.startsWith('http') && !request.url.startsWith('https') && !request.url.includes('assets') &&
    // TODO: check if this will through errors in the future it was this.securityService.jwtToken
    if (this.securityService.user) {
      const authRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${this.securityService.jwtToken}`)
      });
      return next.handle(authRequest);
    } else {
      return next.handle(request);
    }
  }
}
