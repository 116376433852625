// angualr modules
import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { trigger, animate, transition, style } from '@angular/animations';

// animation
import { fade } from '../../shared_animations/fade';

// models
import { ResponseDto } from '../../models/response.model';
import { NotificationDataDto, NotificationDto } from '../../models/notification.model';

// services
import { loadingService } from '../../services/loading.service';
import { CoreService } from '../../services/core.service';
import { AuthService } from './../../../shared/services/auth.service';
import { SidebarTriggerService } from '../../../shared/services/sidebar-trigger.service';
import { MessagingService } from '../../../shared/services/messaging.service';
import { SecurityService } from '../../services/security.service';
import { NotificationService } from '../../services/notification.service';
import { NotificationStatus } from '../../constants/notification-status.constant';
import { Howl, Howler } from 'howler';
import { AlarmService } from '../../services/alarm.service';
import { WebSocketService } from '../../services/web-socket.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('popupAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(5%) ', opacity: 0 }),
        animate('150ms', style({ transform: 'translateY(0) ', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0) ', opacity: 1 }),
        animate('150ms', style({ transform: 'translateY(5%)', opacity: 0 }))
      ])
    ]),
    fade
  ]
})

export class HeaderComponent implements OnInit, AfterViewInit {

  // #region declare variables
  audioOn: any;
  today: Date = new Date();
  hours: any = '';
  mins: any = '';
  seconds: any = '';
  order_id: any = '';
  adminImage = '';
  adminTitle = '';
  currentNotification: any = '';
  timer: any = '';
  notifications = [];
  message: any = [];
  n: any = [];
  pageId: number = 1;
  alertNotification: any = '';
  getNotification: boolean = true;
  displayBoolean: boolean = true;
  loading: boolean = false;
  showLoggedUser: boolean = false;
  emptyNotificationArray: boolean = false;
  showNotification: boolean = false;
  playNotificationSound: boolean = false;

  // names of lists
  listOfNotifications: NotificationDto[];
  listOfWebSocketNotification: any[];

  // names of constatnts
  notificationStatus: { status: string, value: string }[];
  playPromise: HTMLAudioElement;
  sound: any;
  stopNotificationSound: boolean = false
  newPermissions: any;
  userType: any;
  // #endregion

  // #region constructor

  constructor(
    private coreService: CoreService,
    private loader: loadingService,
    private authService: AuthService,
    private sidebarTriggerService: SidebarTriggerService,
    private messagingService: MessagingService,
    private securityService: SecurityService,
    private notificationService: NotificationService,
    public alarmService: AlarmService,
    private ref: ChangeDetectorRef,
    private websocketService: WebSocketService,
    private loginService: LoginService

  ) {

    // init variables
    this.listOfNotifications = [];
    this.notificationStatus = NotificationStatus;
    this.audioOn = true;
    /* -------------- Get User Details ------------------------ */
    const user = this.securityService.retrieveUser();
    if (user) {
      this.adminImage = user.userImage;
      this.adminTitle = user.userName;
    }

    this.loader.loaderObservable.subscribe((params: any) => {
      this.loading = params;
    });
    // Receive Notifications
    // navigator.serviceWorker.addEventListener('message', event => {
    //   if (event.data.data) {
    //     this.message.unshift(event.data); //unshift adds new msg at the 0 index of the array
    //     this.playNotificationSound = true;
    //     setTimeout(() => {
    //       this.playNotificationSound = false;
    //     }, 2000);
    //   }
    // });
  }

  // #endregion

  // #region ngOnInit

  ngOnInit() {

    // this.playSound();
    this.getNotifications();
    this.messagingService.orderUpdatedNotification.subscribe(
      (orderUpdate: any) => {
        // debugger

        this.ref.detectChanges()
        this.getNotifications();

        if (orderUpdate.data.notification_type == "order_created" && !this.stopNotificationSound && this.alarmService.alarmObservable.value == false
        ) {
          this.alarmService.play()

        }

        this.order_id = orderUpdate.data.order_id;

      }
    );

    /* ----------------------- Get Alert Noftification ------------------------ */
    this.messagingService.currentMessage.subscribe(currentMessage => {
      // if (currentMessage.data)
      this.currentNotification = currentMessage;

      currentMessage.forEach(message => {
        this.message.unshift(message); //unshift adds new msg at the 0 index of the array
        this.playNotificationSound = true;
        setTimeout(() => { this.playNotificationSound = false; }, 2000);
      });
    });
    setInterval(() => { this.today = new Date(); }, 1000);

    // websocket
    this.websocketService.websocketEventSubject$.subscribe((websocketEvents: any) => {
      console.log({ websocketEvents });
      this.listOfWebSocketNotification = websocketEvents;
      let lastIndex = websocketEvents.length - 1
      this.newPermissions = websocketEvents[lastIndex]?.permissions
      this.userType = websocketEvents[lastIndex]?.type
      setTimeout(() => { this.listOfWebSocketNotification = []; }, 10000);
      this.changePermission()
    });
  }

  // #endregion

  // #region ngAfterViewInit

  /* ------------------- After View Init ----------------------- */
  ngAfterViewInit() {

    document.getElementById('listBody').addEventListener('scroll', e => {
      const scrollTop = (e.target as HTMLElement).scrollTop;
      const notificationArrayLength = this.message.length;
      const liHeight = (document.querySelector('#listBody > li') as HTMLElement).offsetHeight;
      const viewHeight = (e.target as HTMLElement).clientHeight;
      const totalScroll = (notificationArrayLength - viewHeight / liHeight) * liHeight;
      if (scrollTop >= totalScroll) {
        if (this.getNotification && !this.emptyNotificationArray) {
          this.getNotification = false;
          this.pageId++;
          this.getNotifications();
        }
      }
    });
    // this.hidePopupNotifications();
  }

  // #endregion

  // #region main actions

  /* ------------------- Get Notification ----------------------- */
  // FIXME: call new notification api
  // getNotifications() {
  //   this.coreService
  //     .getMethod('notifications', { page: this.pageId })
  //     .subscribe((notifications: any) => {
  //       notifications.data.notifications.data.length === 0
  //         ? (this.emptyNotificationArray = true)
  //         : null;
  //       notifications.data.notifications.data.forEach(message => {
  //         this.message.push(message);
  //       });
  //       this.getNotification = true;
  //     });
  // }
  changePermission() {
    if (this.userType == "permissionChanges") {
      let permissions = this.securityService.retrieveUser()
      // console.log("permission", permissions.permissions)
      permissions.role = {
        permissions: this.newPermissions
      };
      console.log(permissions.role.permissions, "permissions")
      // console.log(permissions,"new permissions")
      this.loginService.StoreNewPermissions(permissions.role.permissions)
    }


  }
  getNotifications() {
    this.notificationService.getNotifications().subscribe((response: ResponseDto) => {
      response.data.notifications.length ? this.emptyNotificationArray = true : null;
      response.data.notifications.forEach((notification: NotificationDto) => {
        this.listOfNotifications.push(notification)
      });
      this.getNotification = true;
    });
  }


  getNotificationStatusValue(status: any) {
    for (let x in this.notificationStatus) {
    }
  }


  /* --------------------- Sidebar --------------------- */
  sidebarTrigger() {
    this.sidebarTriggerService.toggelSidemenu();
    this.sidebarTriggerService.toggelOverlay();
  }
  /* ----------------- Close Notification --------------------------- */
  closeNotifications(index) {
    this.currentNotification.splice(index, 1);
    this.alarmService.stopAlarm(true)
    this.alarmService.stopAudio()

  }

  // websocket
  closeWebsocketNotificationPopup(index: any) {
    this.listOfWebSocketNotification.splice(index, 1);
  }
  /* ----------------- Hide Notification ----------------------- */
  hidePopupNotifications() {
    setTimeout(() => {
      (document.getElementById(
        'alert-notification'
      ) as HTMLElement).setAttribute(
        'style',
        'transition: 5s; transform: translateY(0); display: none'
      );
      this.displayBoolean = false;
    }, 5000);
  }

  /* ------------------------- Logout ------------------------- */
  logout() {
    this.securityService.logout();
  }

  // #endregion
}
