// angular core
import { Injectable } from '@angular/core';

//angular material
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from "@angular/material/snack-bar";
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  //#region declare variables

  horizontalPosition: MatSnackBarHorizontalPosition;
  verticalPosition: MatSnackBarVerticalPosition;

  //#endregion

  //#region constructor

  constructor(private snackBar: MatSnackBar) { }

  //#endregion

  //#region main actions

  showSuccessMessage(message: string) {
    this.snackBar.open(message, 'x', {
      panelClass: "success-message",
      duration: 2000,
    })
  }

  showWarningMessage(message: any) {
    this.snackBar.open(message, 'x', {
      panelClass: "warning-message",
      duration: 30000,
    })

  }

  showInfoMessage(message: string, closable: boolean = false) {

  }

  showErrorMessage(message: string) {
    this.snackBar.open(message, '', {

      panelClass: ['alert_error'], //grey color
      // panelClass:['error-message'], //red error
      duration: 6000,
      horizontalPosition : 'left',
      verticalPosition : 'top'
    })
  }
}

//#endregion

// .afterDismissed().subscribe((res) => {
// })
