import { CityDto } from "src/app/shared/models/city.model";
import { ListBranchesDto } from "../../client-company/client-company.model";
import { ServiceDto } from "../../orders/order.model";

export class ContractDto {
    contractable!: { name: string };
    create_at!: Date;
    end_date!: Date;
    id!: number;
    service!: { name: string };
    start_date!: Date;
    type!: string;
    status!: string;
    number!: string;
}

export class ContractSearchDto {
    name_or_number!: string;
    type!: string;
    start_date!: string;
    end_date!: string;
    status!: string;
}

export class CreateContractDto {
    type!: any;
    number!: string;
    client_company_id!: number;
    client_id!: number;
    contract_period!: number;
    start_date!: string;
    client_company_ids!: number[];
    client_location_ids!: number[];
    service_id!: number;
    service_ids!: number[];
    visits!: number;
    emergency_visits!: number;
    is_flexible!: number;
    total_price!: number;
    paid_price!: number;
    remaining_unpaid_period!: number;
    notes!: string;
    status!: string;
    media!: any[];
    is_emergency_visits_infinite!: number;
    every_months:number;
}

export class ShowContractDto {
    branches!: ListBranchesDto[];
    contract_period!: number;
    contractable!: { id: number, name: string };
    create_at!: string;
    creator!: { id: number, name: string };
    emergency_visits!: number;
    end_date!: string;
    id!: number;
    locations!: {
        address: string
        city: CityDto
        id: number
        lat: any
        long: any
    }[];
    media!: any[];
    visits!: number;
    number!: string;
    paid_price!: number;
    service!: { id: number, name: string };
    start_date!: string;
    status!: string;
    sub_services!: ServiceDto[];
    total_price!: number;
    type!: string;
    is_flexible!: number;
}

