import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseDto } from 'src/app/shared/models/response.model';
import { SecurityService } from 'src/app/shared/services/security.service';
import { TechnicianCreateDto, TechnicianDto, TechnicianParamsDto } from './technician.model';

// interfaces
export interface ActiveDto { active: number }
@Injectable({
  providedIn: 'root'
})
export class TechnicianService {

  // #region constructor

  constructor(
    private securityService: SecurityService,
    private http: HttpClient
  ) { }

  // #endregion

  // #region technician

  getTechnicians(params: any) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/technicians`;
    return this.http.get<ResponseDto>(url, { params: params });
  }

  deleteTechnician(id: number) {
    const url = `admin/company/${this, this.securityService.getUserCompanySlug}/technicians/${id}`;
    return this.http.delete(url);
  }

  getTechnician(id: number) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/technicians/${id}`;
    return this.http.get<ResponseDto>(url);
  }

  createTechnician(model: TechnicianCreateDto) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/technicians`;
    return this.http.post<ResponseDto>(url, model)
  }

  updateTechnician(id: number, modelUpdate: TechnicianCreateDto) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/technicians/${id}`;
    return this.http.put<ResponseDto>(url, modelUpdate);
  }

  toggleActivity(id: number, model: ActiveDto) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/technicians/${id}`;
    return this.http.put<ResponseDto>(url, model);
  }

  // #endregion

  // #region complementary requests

  getCities() {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/cities`;
    return this.http.get<ResponseDto>(url);
  }

  getNationalities() {
    const url = `nationalities`;
    return this.http.get<ResponseDto>(url);
  }

  getServices() {
    const url = `company/${this.securityService.getUserCompanySlug}/services`;
    return this.http.get<ResponseDto>(url);
  }

  getContractTypes() {
    const params: any = { 'types[]': 'technician_contract' }
    const url = `lookup`;
    return this.http.get<ResponseDto>(url, { params: params })
  }

  updateHoliday(techId: number, holidayId: number) {

    const url = `admin/company/${this.securityService.getUserCompanySlug}/technicians/${techId}/holidays/${holidayId}`;
    return this.http.put<ResponseDto>(url, {});
  }


  // #endregion

  // #region check uniqueness

  checkUniqueness(params: any) {
    const url = `company/${this.securityService.getUserCompanySlug}/check-unique`;
    return this.http.get(url, { params: params });
  }

  // #endregion

  // #region websocket

  sendTechIdToWebsocket(body: { tech_id: number }) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/events/web-current-location`;
    return this.http.post(url, body);
  }

  getTechLastLocation(techId: number) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/technicians/${techId}/last-location`;
    return this.http.get<ResponseDto>(url);
  }

  // #endregion 

  // #region holidays

  checkHoliday(techId: number, body: any) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/technicians/${techId}/check-holiday`;
    return this.http.post(url, body);
  }

  addHoliday(techId, body) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/technicians/${techId}/holidays`;
    return this.http.post(url, body);
  }

  // #endregion

  // #region schedule

  getAllSchedules() {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/schedules`;
    return this.http.get<ResponseDto>(url);
  }

  deleteSchedule(id: number) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/schedules/${id}`;
    return this.http.delete(url);
  }

  getSchedule(id: number) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/schedules/${id}`;
    return this.http.get(url);
  }

  toggleTechSubscription(id: number, body) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/schedules/${id}/attach`;
    return this.http.patch(url, body);
  }
  addSchedule(body) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/schedules`;
    return this.http.post(url, body);
  }

  updateSchedule(id: number, body: any) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/schedules/${id}`
    return this.http.put(url, body);
  }

  getExcelReport(params, id: number) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/technicians/${id}/report`
    return this.http.get(url, { params: params })
  }

  logoutServiceProvider(body) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/events/web-logout`
    return this.http.post(url, body)
  }

  getAppData(body) {

    const url = `admin/company/${this.securityService.getUserCompanySlug}/events/web-initial-data`
    return this.http.post(url, body)
  }
  // #endregion
}
