export class NotificationDto {
    id: number;
    type: string;
    notifiable_id: number;
    data: NotificationDataDto;
}

export class NotificationDataDto {
    order_status: string;
    id: number;
    technician_name: string;
    technician_image: string;
}