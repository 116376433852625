<div class="main_page" id="map">
  <app-alerts></app-alerts>
  <app-side-menu *ngIf="
      (!addOrderMode &&
        !orderDetailsMode &&
        !clientDetailsPageMode &&
        !addOrderMapMode) ||
      clientDetailsMode
    "></app-side-menu>
  <div class="page_main_blocks" [ngClass]="{
      middle_page:
        (!addOrderMode &&
          !orderDetailsMode &&
          !clientDetailsPageMode &&
          !addOrderMapMode) ||
        clientDetailsMode
    }">
    <div class="page_header" *ngIf="
        (    componentRequestingMap!='tech_details' &&!addOrderMode &&
          !orderDetailsMode &&
          !clientDetailsPageMode &&
          !addOrderMapMode) ||
        clientDetailsMode 
      ">
      <app-header></app-header>
    </div>
    <div class="page_body" [ngStyle]="{
        padding:
          orderDetailsMode || clientDetailsPageMode || addOrderMapMode
            ? '0'
            : ''
      }">
      <div class="page_body_title" *ngIf="
          !addOrderMode &&
          !orderDetailsMode &&
          !clientDetailsMode &&
          !clientDetailsPageMode &&
          !addOrderMapMode &&
          url != '/sales/orders-sales-map' &&
          componentRequestingMap!='tech_details'
        ">
        <h3><i class="fas fa-plus"></i>خريطة اﻷعمال</h3>
        <div class="page_body_main_buttons">
          <a routerLink="/orders/all-orders"
            *ngIf="order_all || user.privilege == 'super-admin' || user.privilege == 'owner'">
            كل اﻷعمال
            <i class="fas fa-th-list"></i>
          </a>
          <a routerLink="/orders/add-order"
            *ngIf="order_add || user.privilege == 'super-admin' || user.privilege == 'owner'">
            إضافة طلب
            <i class="fas fa-th-list"></i>
          </a>
          <a routerLink="/orders/orders-table"
            *ngIf="order_all || user.privilege == 'super-admin' || user.privilege == 'owner'">
            جدول اﻷعمال
            <i class="fas fa-th-list"></i>
          </a>
        </div>
      </div>
      <div class="page_body_title" *ngIf="
          !addOrderMode &&
          !orderDetailsMode &&
          !clientDetailsMode &&
          !clientDetailsPageMode &&
          !addOrderMapMode &&
          url == '/sales/orders-sales-map'
        ">
        <h3><i class="fas fa-map-marked-alt"></i>خريطة المبيعات</h3>
        <div class="page_body_main_buttons">
          <a routerLink="/sales/all-sales"
            *ngIf="sales_all || user.privilege == 'super-admin' || user.privilege == 'owner'">
            جدول المبيعات
            <i class="fas fa-table"></i>
          </a>
          <a routerLink="/sales/add-sale"
            *ngIf="sales_add || user.privilege == 'super-admin' || user.privilege == 'owner'">
            إضافة مبيعات جديدة
            <i class="fas fa-plus"></i>
          </a>
          <a routerLink="/orders/orders-table"
            *ngIf="sales_all || user.privilege == 'super-admin' || user.privilege == 'owner'">
            جدول اﻷعمال
            <i class="fas fa-th-list"></i>
          </a>
        </div>
      </div>
      <div class="page_body_title" *ngIf="
          !addOrderMode &&
          !orderDetailsMode &&
          !clientDetailsPageMode &&
          !addOrderMapMode &&
          clientDetailsMode
        ">
        <h3><i class="fas fa-plus"></i>موقع العميل</h3>
      </div>

      <div class="page_body_main">
        <div class="page_block" [ngStyle]="{
            background: addOrderMapMode ? 'none' : '',
            'box-shadow': addOrderMapMode ? 'none' : ''
          }">
          <div class="noData" *ngIf="
              orders.length === 0 &&
              ordersLoaded &&
              !addOrderMapMode &&
              componentRequestingMap!='tech_details'
            ">
            لا يوجد اعمال اليوم
          </div>
          <div *ngIf="componentRequestingMap!='tech_details'" id="filterForms">
            <!-- Open Recommendation Popup -->

            <form [formGroup]="filterForm" style="display: inline-block" *ngIf="
                mapFilter &&
                !orderDetailsMode &&
                !clientDetailsMode &&
                !clientDetailsPageMode &&
                !addOrderMapMode
              " [@fade]>
              <ng-container>
                <div class="form_field">
                  <div class="timePickerContainer">
                    <mat-label>التاريخ </mat-label>
                    <div class="form_input custome__height">
                      <mat-form-field appearance="outline" dir="rtl">
                        <input formControlName="date" class="filterInput mapOrderDateFilter"
                          (dateInput)="orderDateChanged($event)" matInput [matDatepicker]="fromFilterDate"
                          placeholder="التاريخ" autocomplete="off" [value]="getCurrentDate()" id="filterStartDate"
                          [(ngModel)]="orderDate" />
                        <mat-datepicker-toggle class="dateButton" [for]="fromFilterDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker touchUi #fromFilterDate>
                        </mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div (click)="getTechnicians()" class="form_input" style="margin-top: 20px">
                  <mat-label>اسم مقدم الخدمة</mat-label>
                  <mat-form-field dir="rtl" appearance="outline" style="display: block; text-align: right">
                    <mat-select formControlName="technician" [(value)]="selectedTechnicians" multiple
                      placeholder="اختر مقدم الخدمة" id="technician">
                      <!-- <mat-option value="" >كل الفنيين</mat-option> -->
                      <mat-option *ngFor="let technician of listOfTechnicians" [value]="technician.id">
                        {{technician.name}}
                      </mat-option>
                      <!-- only appears if a technician or more were chosen -->
                      <button class="button_add search-btn" (click)="searchByTechnicians()"
                        *ngIf="selectedTechnicians?.length" matTooltipPosition="above" matTooltip="بحث"><i
                          class="fas fa-search"></i></button>
                      <button class="button_add search-btn" (click)="resetTechnicians()"
                        *ngIf="selectedTechnicians?.length" matTooltipPosition="above"
                        matTooltip="إعاة ضبط مقدم الخدمة"><i class="far fa-redo-alt"></i></button>
                    </mat-select>
                  </mat-form-field>
                  <small *ngIf="filterForm.controls.technician.value && !orders.length" style="color: red;">لا يوجد
                    اعمال</small>
                </div>
                <div class="form_input" [formGroup]="cityForm" style="margin-top: 20px">
                  <mat-label>المدن المتاحة </mat-label>
                  <mat-form-field dir="rtl" appearance="outline" style="display: block; text-align: right">
                    <mat-select formControlName="city_id" (selectionChange)="selectCity($event.value)"
                      placeholder="اختر المدينة">
                      <mat-option value="">كل المدن</mat-option>
                      <mat-option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- 

                <div class="badgeCheckbox" style="margin: 0px;">
                  <mat-checkbox   (change)="waitingOrders($event)">
                    طلب انتظار</mat-checkbox>
                </div>
                <div class="badgeCheckbox" style="margin: 0px;">
                  <mat-checkbox   (change)="waitingCompanyOrders($event)">
                   طلب انتظار شركات</mat-checkbox>
                </div> -->

                <div *ngIf="showCompanies" class="form_input" style="margin-top: 20px">
                  <mat-label>الشركات المتاحة </mat-label>
                  <mat-form-field dir="rtl" appearance="outline" style="display: block; text-align: right">
                    <mat-select (selectionChange)="selectCompany($event.value)" placeholder="اختر شركة">
                      <mat-option value="">كل المدن</mat-option>
                      <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="badgeCheckbox" style="margin: 0px;">
                  <mat-checkbox (change)="waitingOrders($event)">
                    طلب انتظار</mat-checkbox>
                </div>

                <div *ngIf="url == '/orders/orders-map' " class="badgeCheckbox" style="margin: 0px;">
                  <mat-checkbox (change)="waitingOrders($event)">
                    طلب انتظار</mat-checkbox>
                </div>
                <div *ngIf="url == '/orders/orders-map' " class="badgeCheckbox" style="margin: 0px;">
                  <mat-checkbox (change)="waitingCompanyOrders($event)">
                    طلب انتظار عقود</mat-checkbox>
                </div>

                <div *ngIf="isWaitingCompany" class="form_input" [formGroup]="companyForm" style="margin-top: 20px">
                  <mat-label>الشركات المتاحة </mat-label>
                  <mat-form-field dir="rtl" appearance="outline" style="display: block; text-align: right">
                    <mat-select formControlName="company_id" (selectionChange)="selectCompany($event.value)"
                      placeholder="اختر شركة">
                      <mat-option value="">كل المدن</mat-option>
                      <mat-option *ngFor="let contractsOrder of contractsOrders" [value]="contractsOrders.id">
                        {{ contractsOrders.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>


                <div class="form_input" style="margin-top: 20px">
                  <a type="button" alt="جدول اﻷعمال" title="جدول اﻷعمال" routerLink="/orders/orders-table"
                    target="_blank" style="
                      width: 54px;
                      display: inline-block;
                      text-align: center;
                      line-height: 49px;
                      height: 49px;
                      border-radius: 10px;
                      background: #f6a811;
                      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    ">
                    <i class="fas fa-bars"></i>
                  </a>
                </div>
              </ng-container>
            </form>

            <div id="fade" *ngIf="showPopup" [@fade]>
              <form [formGroup]="timeFilterForm" *ngIf="addOrderMode">
                <div id="filterTime">
                  <h4>نتيجة البحث</h4>
                  <i class="fas fa-times" (click)="closeSuggestTechnicians()"></i>
                  <div class="allTime">
                    <div id="filterTimeContainer">
                      <div class="form_field">
                        <div class="timePickerContainer">
                          <mat-label>التاريخ </mat-label>
                          <div class="form_input custome__height">
                            <mat-form-field appearance="outline" dir="rtl">
                              <input class="filterInput mapOrderDateFilter" (dateInput)="orderDateChanged($event)"
                                matInput [min]="todayDate" [matDatepicker]="fromFilterDate" placeholder="التاريخ"
                                autocomplete="off" id="aiDate" formControlName="orderDate" />
                              <mat-datepicker-toggle class="dateButton" [for]="fromFilterDate">
                              </mat-datepicker-toggle>
                              <mat-datepicker touchUi #fromFilterDate>
                              </mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="timeField">
                        <div class="form_field" id="timePicker">
                          <div class="timePickerContainer">
                            <mat-label>الوقت من </mat-label>
                            <input formControlName="startObj" placeholder="الوقت من" [ngxTimepicker]="startTime"
                              readonly />
                            <!-- (timeSet)="timeChanged($event, 'start')" -->
                            <ngx-material-timepicker #startTime enableKeyboardInput="true"
                              [ngxMaterialTimepickerTheme]="darkTheme">
                            </ngx-material-timepicker>
                          </div>
                          <div [@fade] class="validationState errorState"
                            *ngIf="timeFilterForm.hasError('endAfterStart')">
                            <i class="fas fa-times"></i>
                            لايمكن أن يكون وقت إنتهاء الطلب قبل وقت بدء الطلب
                          </div>
                        </div>
                        <div class="form_field" id="timePicker2">
                          <div class="timePickerContainer">
                            <mat-label>الوقت الى </mat-label>
                            <input formControlName="endObj" placeholder="الوقت الي" [ngxTimepicker]="endTime"
                              readonly />
                            <!--  (timeSet)="timeChanged($event, 'end')" -->
                            <ngx-material-timepicker #endTime enableKeyboardInput="true"
                              [ngxMaterialTimepickerTheme]="darkTheme">
                            </ngx-material-timepicker>
                          </div>
                        </div>
                      </div>
                      <div class="form_field">
                        <button (click)="getFreeTechniciansForNewOrder()" type="button" [disabled]="
                            timeFilterForm.hasError('endAfterStart') ||
                            timeFilterForm.invalid
                          ">
                          <span class="searchingBestTechnicalState">
                            <i class="fas fa-cog" *ngIf="bestTechnicalFillterprocess" [@fade]></i></span>
                          <span *ngIf="!bestTechnicalFillterprocess" [@fade]>
                            البحث عن أفضل مقدم الخدمة
                          </span>
                        </button>
                      </div>
                    </div>
                    <div class="filterTimeTaken" *ngIf="!AIData.emptyTechnicians.length">
                      <p>اسم مقدم الخدمة <span>الوقت المستغرق</span></p>
                      <span class="bestTechnical" *ngIf="searchingLoaderCog">
                        <p>
                          <input *ngIf="sortedArray.length" type="radio" id="selectTechnicalRadio"
                            class="selectTechnical" value="{{ sortedArray[0].technical.id }}" />
                          {{ searchingLoaderCog ? bestTechnicalName : "" }}
                          <span>{{
                            searchingLoaderCog ? bestTechnicalArriveTime : ""
                            }}
                            د
                          </span>
                        </p>
                        <!-- (click)="submitSelectedTechnical()" -->
                        <button type="button" class="selectedTechnicalSubmit">
                          تأكيد
                        </button>
                      </span>
                      <p class="emptyBestTechnicalList" *ngIf="!searchingLoaderCog">
                        لا يوجد ترشيجات فى الوقت الحالى
                      </p>
                    </div>
                    <div class="filterTimeTaken" *ngIf="AIData.emptyTechnicians.length">
                      <p>اسم مقدم الخدمة <span>الوقت المستغرق</span></p>
                      <span class="bestTechnical">
                        <div *ngFor="
                            let technical of AIData.emptyTechnicians;
                            let i = index
                          ">
                          <p>
                            <!-- (change)="radioBtn(i)" -->
                            <input *ngIf="AIData.emptyTechnicians.length" type="radio" id="selectTechnicalRadio"
                              class="selectTechnical" value="{{ technical.id }}" />
                            {{ technical.name }}
                            <span> د </span>
                          </p>
                        </div>
                      </span>
                      <!-- (click)="submitSelectedTechnical()" -->
                      <button type="button" class="selectedTechnicalSubmit">
                        تأكيد
                      </button>
                      <p class="emptyBestTechnicalList" *ngIf="!searchingLoaderCog">
                        لا يوجد ترشيجات فى الوقت الحالى
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- (mapClick)="mapClicked($event)" -->
          <agm-map #gm [streetViewControl]="false" [fullscreenControl]="true" [latitude]="
              orderDetailsMode || clientDetailsMode
                ? mapLatAndLong.lat
                : clientDetailsPageMode || addOrderMapMode
                ? clientDetailsOrigins?.lat
                : lat
            " [longitude]="
              orderDetailsMode || clientDetailsMode
                ? mapLatAndLong.lng
                : clientDetailsPageMode || addOrderMapMode
                ? clientDetailsOrigins?.lng
                : lng
            " #AgmMap [fitBounds]="
              orderDetailsMode ||
              clientDetailsMode ||
              clientDetailsPageMode ||
              addOrderMapMode
                ? false
                : true
            " [mapTypeControl]="true" backgroundColor="ffffff" id="map" [ngStyle]="{
              height: orderDetailsMode
                ? '400px'
                : clientDetailsPageModeAddMap
                ? '400px'
                : clientDetailsPageModeDetailsMap
                ? '250px'
                : addOrderMapMode
                ? '200px'
                :  ''
            }" [zoom]="zoom" [agmDrawingManager]="drawing">

            <agm-drawing-manager #drawing="agmDrawingManager" [drawingMode]="drawingMode"
              (overlayComplete)="onOverlayComplete($event)" [drawingControlOptions]="drawingControlOptions"
              [polygonOptions]="polygonOptions">
            </agm-drawing-manager>

            <agm-polygon *ngFor="let iterator of polygons; index as i" (polyMouseDown)="onClickPolygon(i)"
              (polyMouseUp)="onEditPolygon(i)" (polyDragEnd)="onEditPolygon(i)"
              [fillOpacity]="polygonOptions.fillOpacity" [fillColor]="polygonOptions.fillColor"
              [strokeColor]="polygonOptions.strokeColor" [strokeWeight]="polygonOptions.strokeWeight"
              [polyDraggable]="polygonOptions.draggable" [editable]="polygonOptions.editable" [paths]="iterator">
            </agm-polygon>
            <!-- <agm-marker
              *ngIf="
                ordersLoaded &&
                !orderDetailsMode &&
                !clientDetailsMode &&
                !clientDetailsPageMode &&
                !addOrderMapMode &&
                !addNewAddressMode
              "
              [agmFitBounds]="true"
              [latitude]="vidCoordinates.lat"
              [longitude]="vidCoordinates.long"
              iconUrl="./../../../../assets/img/marker_vid.png"
            >
            </agm-marker> -->

            <!-- <agm-marker *ngIf="orderClientSales && url == '/sales/draft-sales'" [agmFitBounds]="true"
              [latitude]="clientDetailsSalesOrgins.lat" [longitude]="clientDetailsSalesOrgins.lng">
            </agm-marker> -->

            <!-- companyPin  '../../../assets/img/company.png'-->
            <ng-container *ngIf="
                cities.length &&
                (url == '/orders/orders-map' || url == '/sales/orders-sales-map') && url != '/sales/draft-sales' ">
              <agm-marker *ngFor="let city of citiesLating" [agmFitBounds]="true" [latitude]="city.lat"
                [longitude]="city.long" [iconUrl]="{url:companyPin,scaledSize: { height: 20, width: 50 }}">
              </agm-marker>
            </ng-container>


            <ng-container *ngIf="componentRequestingMap!='tech_details'">
              <agm-marker *ngFor="let city of citiesLating" [agmFitcheckBounds]="true" [latitude]="city.lat"
                [longitude]="city.long" [iconUrl]="{url:companyPin,scaledSize: { height: 20, width: 50 }}">
              </agm-marker>
            </ng-container>


            <div *ngFor="let waitingCompOrder of waitingCompanyOrdersArray">
              <agm-marker *ngIf="isWaitingCompany" [agmFitBounds]="true" [latitude]="waitingCompOrder.location?.lat"
                [longitude]="waitingCompOrder.location?.long" [iconUrl]="{url:'../../../assets/img/pin.gif'}">
              </agm-marker>
            </div>
            <div *ngFor="let waitingOrder of waitingOrdersArray">
              <agm-marker *ngIf="isWaitingOrder" [agmFitBounds]="true" [latitude]="waitingOrder.location?.lat"
                [longitude]="waitingOrder.location?.long" [iconUrl]="{url:'../../../assets/img/pin.gif'}">
              </agm-marker>
            </div>


            <ng-container *ngIf="
                orders.length > 0 &&
                ordersLoaded &&
                !orderDetailsMode &&
                !clientDetailsMode &&
                !clientDetailsPageMode &&
                !addOrderMapMode &&
                url != '/sales/draft-sales'
              ">
              <!-- iconUrl="./../../../../assets/img/marker_{{
              order.technical.id
            }}.png" -->
              <!-- <div *ngFor="let order of orders">
                <agm-marker [agmFitBounds]="true" *ngIf="order.status_relation.id == 19 || order.status_relation.id == 20 || order.status_relation.id == 21 ||
                  order.status_relation.id == 41" [latitude]="order.location?.lat" [longitude]="order.location?.long"
                  class="markerTec" [iconUrl]="{
                    'url': order.technician?.image_pin? order.technician?.image_pin :
                    './../../../../assets/img/technician_pin.png',
                    'scaledSize': {
                    'width': 50,
                    'height': 70
                    }
                  }" (markerClick)="orderDetails(infoWindow, gm)">
                  <div class="infoStyle" id="test" style="z-index: 99999">
                    <agm-info-window #infoWindow [latitude]="order.location?.lat" [longitude]="order.location?.long"
                      [zIndex]="9999999">
                      <ul style="position: relative; z-index: 99999999">
                        <li class="number">رقم الطلب : {{ order?.order_number }}</li>
                        <li>اسم العميل : {{ order.client?.name }}</li>
                        <li>اسم الفني : {{ order.technician?.name }}</li>
                        <li>
                          وقت تنفيذ الطلبية :
                          <ng-container>
                            <span class="timeTitleSpan">من : </span>{{
                              order.start?.hour > 12
                                ? order.start?.hour - 12 + ":" + order.start?.minute + "م"
                                : order.start?.hour + ":" + order.start?.minute + "ص"
                            }}
                          </ng-container>
                          <ng-container>
                            <span class="timeTitleSpan"> الي : </span>{{
                              order.end?.hour > 12
                                ? order.end?.hour - 12 + ":" + order.end?.minute + "م"
                                : order.end?.hour + ":" + order.end?.minute + "ص"
                            }}
                          </ng-container>
                        </li>
                        <li *ngIf="order.offer ? true : false">
                          اسم العرض : {{ order.offer?.name }}
                        </li>
                        <li>
                          الملاحظات :
                          <div [innerHTML]="order.details"></div>
                        </li>
                        <li>
                          <a [routerLink]="['/orders/order-details', order.id]">تفاصيل الطلب</a>
                        </li>
                      </ul>
                    </agm-info-window>
                  </div>
                </agm-marker>
              </div> -->
              <!-- waiting orders  -->


              <div *ngFor="let order of orders">

                <agm-marker [agmFitBounds]="true"
                  [latitude]="order.location?.lat? order.location?.lat :+order.client_company?.lat" *ngIf="order.status_relation.id == 19 || order.status_relation.id == 20 || order.status_relation.id == 21 ||
                  order.status_relation.id == 41 && componentRequestingMap!='tech_details'"
                  [longitude]="order.location?.long? order.location?.long: +order.client_company?.long"
                  class="markerTec" [iconUrl]="{
                  'url': order.technician?.image_pin? order.technician?.image_pin :
                  './../../../../assets/img/technician_pin.png',
                  'scaledSize': {
                  'width': 70,
                  'height': 100
                  }}" style="opacity: 0" (markerClick)="orderDetails(infoWindow, gm)">
                  <div class="infoStyle" id="test" style="z-index: 99999">
                    <agm-info-window #infoWindow
                      [latitude]="order.location?.lat? order.location?.lat :+order.client_company?.lat"
                      [longitude]="order.location?.long? order.location?.long : +order.client_company?.long"
                      [zIndex]="9999999">
                      <ul style="position: relative; z-index: 99999999">
                        <li class="number">رقم الطلب : {{ order?.order_number }}</li>
                        <li>اسم العميل : {{ order.client?.name }}
                          {{ order.client_company?.parent_client_company?.commercial_register}}
                          {{order.client_company?.name}}</li>
                        <li>اسم مقدم الخدمة : {{ order.technician?.name }}</li>
                        <li>
                          وقت تنفيذ الطلبية :
                          <ng-container>
                            <span class="timeTitleSpan">من : </span>{{
                            order.start?.hour > 12
                            ? order.start?.hour - 12 + ":" + order.start?.minute + "م"
                            : order.start?.hour + ":" + order.start?.minute + "ص"
                            }}
                          </ng-container>
                          <ng-container>
                            <span class="timeTitleSpan"> الي : </span>{{
                            order.end?.hour > 12
                            ? order.end?.hour - 12 + ":" + order.end?.minute + "م"
                            : order.end?.hour + ":" + order.end?.minute + "ص"
                            }}
                          </ng-container>
                        </li>
                        <li *ngIf="order.offer ? true : false">
                          اسم العرض : {{ order.offer?.name }}
                        </li>
                        <li>
                          الملاحظات :
                          <div [innerHTML]="order.details"></div>
                        </li>
                        <li>
                          <a [routerLink]="['/orders/order-details', order.id]">تفاصيل الطلب</a>
                        </li>
                      </ul>
                    </agm-info-window>
                  </div>
                  <div class="infoStyle2" id="test2">
                    <agm-overlay [latitude]="order.location?.lat? order.location?.lat :+order.client_company?.lat"
                      [longitude]="order.location?.long? order.location?.long :+order.client_company?.long">
                      <div class="timePin">
                        <!-- <span>
                    من
                  </span> -->
                        <span>
                          {{
                          order.start?.hour > 12
                          ? " PM " + (order.start?.hour - 12) + ":" + order.start?.minute
                          : " AM " + order.start?.hour + ":" + order.start?.minute
                          }}
                          :
                        </span>
                        <!-- <span>
                    الي
                  </span> -->
                        <span>
                          {{
                          order.end?.hour > 12
                          ? " PM " + (order.end?.hour - 12) + ":" + order.end?.minute
                          : " AM " + order.end?.hour + ":" + order.end?.minute
                          }}
                        </span>
                      </div>
                    </agm-overlay>
                  </div>
                </agm-marker>
              </div>
            </ng-container>
            <!-- *ngIf="
                ordersLoaded && addOrderMode && url != '/sales/draft-sales'
              " -->
            {{mapLatAndLong?.lat}}
            <!-- <agm-marker *ngIf="" [agmFitBounds]="true" [latitude]="clientDetailsOrigins?.lat"
              [longitude]="clientDetailsOrigins?.long">
            </agm-marker> -->
            <agm-marker *ngIf="
                (orderDetailsMode || clientDetailsMode) &&
                url != '/sales/draft-sales ' || componentRequestingMap=='tech_details'" [agmFitBounds]=" true"
              [markerDraggable]="true" [latitude]="mapLatAndLong?.lat" [longitude]="mapLatAndLong?.lng">
            </agm-marker>
            <!-- -------------- marker here -->
            <agm-marker *ngIf="techDetailsMode &&url != '/sales/draft-sales' && componentRequestingMap!='tech_details'"
              [agmFitBounds]="
              true" [markerDraggable]="false" [latitude]="mapLatAndLong?.lat" [longitude]="mapLatAndLong?.lng">
              <div class="infoStyle" id="test" style="z-index: 99999">
                kl;fwmkl;dwemfk
              </div>
            </agm-marker>
            <!-- <agm-direction *ngIf="
                (orderDetailsMode || clientDetailsMode) &&
                url != '/sales/draft-sales'
              " [origin]="mapLatAndLong" [destination]="destination ? destination : ''">
            </agm-direction> -->


            <agm-marker *ngIf="
                citiesLating.length &&
                url == '/sales/add-sale' &&
                url != '/sales/draft-sales'
              " [markerDraggable]="true" (dragEnd)="markerDragEnd($event)" [agmFitBounds]="true"
              [latitude]="clientDetailsOrigins?.lat" [longitude]="clientDetailsOrigins?.lng"
              iconUrl="./../../../../assets/img/map-marker-check.png">
            </agm-marker>

            <agm-marker *ngIf="
                url == '/locations-managment' && url != '/sales/draft-sales'
              " [markerDraggable]="true" (dragEnd)="markerDragEnd('amira')" [agmFitBounds]="true"
              [latitude]="clientDetailsOrigins?.lat" [longitude]="clientDetailsOrigins?.lng" [iconUrl]="{
                url: companyPin,
                scaledSize: { height: 50, width: 50 }
              }">
            </agm-marker>

            <agm-marker [markerDraggable]="true" (dragEnd)="markerDragEnd($event)" [agmFitBounds]="true"
              [latitude]="clientDetailsOrigins?.lat" [longitude]="clientDetailsOrigins?.lng">
            </agm-marker>
            <div *ngIf="
                ((url == '/orders/orders-map' ||
                  url.includes('/sales/orders-sales-map')) &&
                  sales_all) ||
                (user.privilege == 'super-admin' && url != '/sales/draft-sales')
              ">
              <agm-marker [agmFitBounds]="true" *ngFor="let sale of salesOrders" [latitude]="sale.location.lat"
                [longitude]="sale.location.long" (markerClick)="showSalesPopup(sale)" class="markerTec" [iconUrl]="{
                  url: ''
                }" [opacity]="0" [zIndex]="9999">
              </agm-marker>
              <agm-marker *ngFor="let sale of salesOrders" [latitude]="sale.location.lat"
                (markerClick)="showSalesPopup(sale)" [longitude]="sale.location.long" [opacity]="0" [zIndex]="9999">
                <agm-overlay *ngFor="let sale of salesOrders" [latitude]="sale.location.lat"
                  [longitude]="sale.location.long">
                  <div *ngIf="!sale.technician" class="pin" [ngStyle]="{ background: sale['label-color'] }"></div>
                  <div *ngIf="sale.technician" class="overlayTech">
                    <img [src]="sale.technician.imagePin" alt="" />
                  </div>

                  <div class="timePin2" [ngStyle]="{ top: !sale.technician ? '0px' : '-53px' }">
                    <span>
                      {{
                      sale.fromH > 12
                      ? " PM " + (sale.fromH - 12) + ":" + sale.fromM
                      : " AM " + sale.fromH + ":" + sale.fromM
                      }}
                      :
                    </span>
                    <span>
                      {{
                      sale.toH > 12
                      ? " PM " + (sale.toH - 12) + ":" + sale.toM
                      : " AM " + sale.toH + ":" + sale.toM
                      }}
                    </span>
                  </div>
                  <div class="statusPandding" *ngIf="sale.technician">
                    Pendding
                  </div>
                </agm-overlay>
              </agm-marker>
            </div>


          </agm-map>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showSale && (sales_all || user.privilege == 'super-admin')" class="salePopup">
    <header style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        background: #333;
        color: #fff;
      ">
      <h3>
        تفاصيل الطلب الخاص بالعميل
        {{
        updateSaleOrder["client"]
        ? updateSaleOrder["client"]["user"]["name"]
        : ""
        }}
      </h3>
      <i class="fas fa-times-circle" (click)="showSale = false"></i>
    </header>
    <div class="sale-details">
      <form [formGroup]="salesForm">
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="routerToEdit($event)" style="word-break: break-all">
          <mat-tab label="تفاصيل الطلب" *ngIf="sales_all || user.privilege == 'super-admin'">
            <div style="padding: 20px; text-align: center">
              <ul class="orderSalesDetails">
                <li *ngIf="updateSaleOrder['client']">
                  <span class="saleOrderTitle" *ngIf="updateSaleOrder['client']">اسم العميل :</span>
                  <span>{{
                    updateSaleOrder["client"]
                    ? updateSaleOrder["client"]["user"]["name"]
                    : ""
                    }}</span>
                </li>
                <li *ngIf="updateSaleOrder['technician']">
                  <span class="saleOrderTitle" *ngIf="updateSaleOrder['technician']">اسم مقدم الخدمة :</span>
                  <span>{{
                    updateSaleOrder["technician"]
                    ? updateSaleOrder["technician"]["name"]
                    : ""
                    }}</span>
                </li>
                <li *ngIf="updateSaleOrder['order_date']">
                  <span class="saleOrderTitle" *ngIf="updateSaleOrder['order_date']">تاريخ الطلب :</span>
                  <span>{{
                    updateSaleOrder["order_date"]
                    ? updateSaleOrder["order_date"]
                    : ""
                    }}</span>
                </li>
                <li>
                  <span class="saleOrderTitle">وقت تنفيذ الطلبية :</span>
                  <ng-container>
                    <span class="timeTitleSpan" style="margin-left: 10px">من :
                    </span>
                    <span class="timeTitleSpan" style="font-family: 'Roboto'; font-weight: 500">
                      {{
                      updateSaleOrder["fromH"] > 12
                      ? updateSaleOrder["fromH"] -
                      12 +
                      ":" +
                      updateSaleOrder["fromM"] +
                      " PM "
                      : updateSaleOrder["fromH"] +
                      ":" +
                      updateSaleOrder["fromM"] +
                      " AM "
                      }}
                    </span>
                  </ng-container>
                  <ng-container>
                    <span class="timeTitleSpan" style="margin: 0 10px">
                      الي :
                    </span>
                    <span class="timeTitleSpan" style="font-family: 'Roboto'; font-weight: 500">
                      {{
                      updateSaleOrder["toH"] > 12
                      ? updateSaleOrder["toH"] -
                      12 +
                      ":" +
                      updateSaleOrder["toM"] +
                      " PM "
                      : updateSaleOrder["toH"] +
                      ":" +
                      updateSaleOrder["toM"] +
                      " AM "
                      }}
                    </span>
                  </ng-container>
                </li>
                <li *ngIf="updateSaleOrder['service']">
                  <span class="saleOrderTitle" *ngIf="updateSaleOrder['service']">الخدمة الرئيسية :</span>
                  <span>{{
                    updateSaleOrder["service"]
                    ? updateSaleOrder["service"]["parent_service"]["name"]
                    : ""
                    }}</span>
                </li>
                <li *ngIf="updateSaleOrder['service']">
                  <span class="saleOrderTitle" *ngIf="updateSaleOrder['service']">الخدمة الفرعية :</span>
                  <span>{{
                    updateSaleOrder["service"]
                    ? updateSaleOrder["service"]["name"]
                    : ""
                    }}</span>
                </li>
                <li>
                  <span class="saleOrderTitle">الملاحظات :</span>
                  <span [innerHTML]="updateSaleOrder['details']"></span>
                </li>
              </ul>
            </div>
          </mat-tab>
          <mat-tab label="تغيير اللون" *ngIf="
              (!updateSaleOrder['technician'] && sales_update) ||
              user.privilege == 'super-admin'
            ">
            <div class="form_field" style="margin: 20px 0; padding: 20px 0; height: 130px">
              <mat-form-field style="width: 58%" appearance="outline" dir="rtl">
                <input matInput placeholder="" [value]="color" autocomplete="off" id="name"
                  (keyup)="changeColor($event.target.value)" />
              </mat-form-field>
              <p-colorPicker class="color-system" [(ngModel)]="color" formControlName="color"></p-colorPicker>
            </div>
            <button type="button" (click)="updateColor()" style="
                display: block;
                background: #f6a811;
                color: #242a2d;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                border-radius: 2px;
                padding: 8px 30px;
                margin-bottom: 15px;
                position: relative;
                font-family: Arabic gess Bold;
              ">
              تغيير اللون
            </button>
          </mat-tab>
          <mat-tab label="تحويل الطلب" *ngIf="
              (updateSaleOrder['technician_id'] && sales_update) ||
              user.privilege == 'super-admin'
            ">
            <div class="form_field full_width" style="margin: 20px 0; padding: 15px">
              <mat-checkbox (change)="confrimSale($event.checked)">تحويل الطلب الى التنفيذ
              </mat-checkbox>
            </div>
          </mat-tab>
          <mat-tab label="تعديل الطلب" *ngIf="sales_update || user.privilege == 'super-admin'"></mat-tab>
        </mat-tab-group>
      </form>
    </div>
  </div>
</div>