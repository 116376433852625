import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HeadersService } from "./headers.service";
@Injectable()
export class CoreService {
  constructor(
    private httpClient: HttpClient,
    private headersService: HeadersService
  ) { }
  /* --------------------------- Post Method -------------------------- */
  postMethod(url, data) {
    if (url !== "tkn") {
    } else {
      // baseURL = this.headersService.baseAPI + "api/";
    }
    if (url === "logout") {
      // baseURL = this.headersService.baseAPI + "api/";
      return this.httpClient.post(url, data, {
        params: { ...data },
      });
    }
    return this.httpClient.post(  url, data, {
    });
  }
  /* --------------------------- Get Method -------------------------- */
  superPost(url, data) {
    return this.httpClient.post( url, data, {
    });
  }
  /* --------------------------- Get Method -------------------------- */
  getMethod(url, params?) {
  
    return this.httpClient.get( url, {
      params: { ...params },
    });
  }
  /* --------------------------- Get Method -------------------------- */
  superGet(url, params?) {

    return this.httpClient.get( url, {
      params: { ...params },
    });
  }
  /* --------------------------- Delete Method -------------------------- */
  deleteMethod(url) {
    return this.httpClient.delete( url, {
    });
  }
  /* ---------------------- Put Method ----------------------- */
  updateMethod(url, data) {
    return this.httpClient.put(url, data, {
    });
  }
  /* ------------------- Put Method -------------------------- */
  superUpdate(url, data) {
    return this.httpClient.put( data, {
    });
  }
  superDelete(url) {
    return this.httpClient.delete( url, {
    });
  }
}
