// angular modules
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewChildren, QueryList } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import * as socket from 'socket.io-client';

// agm
import { AgmMap, AgmPolygon, ControlPosition, LatLng, LatLngLiteral } from "@agm/core";
import { MatSelect } from "@angular/material/select";

// animation
import { fade } from "../../shared_animations/fade";
import { fadeBottomTop } from "../../shared_animations/fade-bottom-top";

// models
import { UserDto } from "../../models/user.model";
import { ResponseDto } from "../../models/response.model";
import { CityDto } from "../../models/city.model";
import { PermissionDto } from "../../models/permission.model";
import { OrderDto } from "src/app/modules/orders/order.model";
import { TechnicianDto } from "src/app/modules/technicians/technician.model";

// rxjs
import { Observable } from "rxjs";


// services
import { CoreService } from "../../services/core.service";
import { startEndTimeValidator } from "../../../shared/shared_validators/StartEndTime.validator";
import { ResponseStateService } from "../../../shared/services/response-state.service";
import { SecurityService } from "../../services/security.service";
import { SharedService } from "../../services/shared.service";
import { OrdersService } from "../../services/orders.service";
import { TechnicianService } from "../../../modules/technicians/technician.service";
import { WebSocketService } from "../../services/web-socket.service";
import { ClientCompanyService } from "../../../modules/client-company/client-company.service";
import { ContractService } from "../../../modules/contracts/services/contract.service";
import { DrawingControlOptions, OverlayType } from "@agm/drawing";

declare var google: any;
@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  animations: [fade, fadeBottomTop],
})
export class MapComponent implements OnInit {

  // #region declare variables

  // viewChild
  @ViewChild(MatSelect) matSelect: MatSelect;
  @ViewChildren(AgmPolygon) public polygonRefs!: QueryList<AgmPolygon>;


  // outputs
  @Output() clickedNewClientOrgin: EventEmitter<any> = new EventEmitter();
  @Output() bestTechnicalSelected: EventEmitter<any> = new EventEmitter();

  // inputs
  @Input() orderDate;
  @Input() orderClient;
  @Input() mapLatAndLong;
  @Input() clientDetailsOrigins;
  @Input() addOrderMode = false;
  @Input() addOrderMapMode = false;
  @Input() orderDetailsMode = false;
  @Input() clientDetailsPageMode = false;
  @Input() clientDetailsPageModeAddMap = false;
  @Input() clientDetailsPageModeDetailsMap = false;
  @Input() techDetailsMode: boolean = false;
  // for tracking tech
  @Input() componentRequestingMap: string = '';
  @Input() destination: any;
  @Input() trackingPolygons: any
  @Input() technicianId: number
  mapRef!: AgmMap;
  // zoom: number = 15;
  // lat: number = 28.626137;
  // lng: number = 79.821603;

  activePolygonIndex!: number;
  drawingMode: any = null;

  drawingControlOptions: DrawingControlOptions = {
    position: ControlPosition.LEFT_CENTER,
    drawingModes: [
      OverlayType.POLYGONE
    ]
  }

  polygonOptions = {
    fillOpacity: 0.5,
    fillColor: 'red',
    strokeColor: 'red',
    strokeWeight: 7,
    draggable: false,
    editable: false
  }

  deleteIconStyle = {
    cursor: 'pointer',
    backgroundImage: 'url(../assets/images/remove.png)',
    height: '24px',
    width: '24px',
    marginTop: '5px',
    backgroundColor: '#fff',
    position: 'absolute',
    top: "2px",
    left: "52%",
    zIndex: 99999
  }


  polygons: LatLngLiteral[][] = [
    // [
    //   { lat: 28.630818281028954, lng: 79.80954378826904 },
    //   { lat: 28.62362346815063, lng: 79.80272024853515 },
    //   { lat: 28.623585797675588, lng: 79.81490820629882 },
    //   { lat: 28.630818281028954, lng: 79.82619494183349 }
    // ]
    // [
    //   { lat: 28.63130796240949, lng: 79.8170110581665 },
    //   { lat: 28.623623468150655, lng: 79.81705397351074 },
    //   { lat: 28.623623468150655, lng: 79.82619494183349 },
    //   { lat: 28.6313832978037, lng: 79.82619494183349 },
    //   { lat: 28.63130796240949, lng: 79.8170110581665 }
    // ]
  ]
  isWaitingOrder = false;
  isWaitingCompany = false
  sortedArray: any = [];
  technicians: any = [];
  responseState;
  responseData;
  companyPin;
  private geoCoder;
  citiesLating = [];
  companiesLating = [];
  ordersLoaded: boolean = false;
  bestTechnicalFillterprocess: boolean = false;
  mapFilter: boolean = true;
  searchingLoaderCog: boolean = false;
  order_add: boolean = false;
  order_all: boolean = false;
  order_update: boolean = false;
  order_delete: boolean = false;
  ordersModule: any = [];
  sales_add: boolean = false;
  sales_all: boolean = false;
  sales_update: boolean = false;
  sales_delete: boolean = false;
  sales: any = [];
  user: UserDto;
  bestTechnicalName: any = "";
  bestTechnicalArriveTime: any = "";
  technicalIndex: any = "";
  lat: any = 39.2107495;
  lng: any = 21.6145938;
  zoom: number = 5
  todayDate: Date = new Date();
  // View Child of Map
  @ViewChild("AgmMap") agmMap: AgmMap;
  orders: any = [];
  vidCoordinates: any = {
    long: 39.2107495,
    lat: 21.6145938,
  };
  // AI
  enableAI: boolean = false;
  AIData: any = {
    emptyTechnicians: [],
    orders: [],
  };
  showPopup: boolean = false;
  // filter form 
  filterForm = new FormGroup(
    {
      date: new FormControl(""),
      technician: new FormControl(""),
      city: new FormControl(""),
    }
  )
  /* -------------------- Time Form ----------------------------- */
  timeFilterForm = new FormGroup(
    {
      orderDate: new FormControl(),
      start: new FormControl(""),
      startObj: new FormControl("", Validators.required),
      end: new FormControl(""),
      endObj: new FormControl("", Validators.required),
    },
    startEndTimeValidator
  );

  servicesWithTechniciansList: any = [];
  techniciansFilterPlaceholder: any = "اسم مقدم الخدمة او الخدمة";
  nestedType: any = "nested";
  filterTechniciansComponentId: any = "filterTechnicians";
  filteredTechniciansIds: any = [];
  // Client Map Location
  clientDetailsMode = false;
  cities: CityDto[];
  cityForm = new FormGroup({
    city_id: new FormControl(""),
  });
  city_id: number;
  citiesFilteredOptions: Observable<any>;
  url: any = "";
  salesOrders = [];
  showSale = false;
  salesForm = new FormGroup({
    color: new FormControl(""),
  });
  color: any = "#000";
  confirmSales = false;
  updateSaleOrder = {};
  updateSaleOrder_id = "";
  paramDate: any = "";
  showCompanies: boolean;
  companies: any[];

  showContractsOrders: boolean;
  waitingCompanyOrdersArray: any[];
  waitingOrdersArray: any;
  contractsOrders: any;
  contractsOrdersLating: any;

  selectedTechnicians: number[] = [];
  listOfTechnicians: TechnicianDto[] = [];
  filteredTechnicians: string = '';
  private socket: any
  polygonsPositions: any = []
  array = []

  // #endregion

  // #region constructor

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private securityService: SecurityService,
    private sharedService: SharedService,
    private orderService: OrdersService,
    private technicianService: TechnicianService,
    private clientCompanyService: ClientCompanyService,
    private websocketService: WebSocketService,
    private contracts: ContractService
  ) {
    // init variables

    // gets current url
    this.url = this.router.url;
    this.user = this.securityService.retrieveUser();
    this.companyPin = this.user.companyPin;
    this.cities = [];
    this.companies = []
    this.showCompanies = false;
    this.showContractsOrders = false;
    this.contractsOrdersLating = []
    this.contractsOrders = []

    // user permissions
    if (this.user.privilege !== 'super-admin') {
      this.getUserPermissions();
    }
    if (!this.clientDetailsPageMode) {
      this.getCities();
    }


    this.orderDate = "";

  }

  // #endregion 
  onLoadMap($event: AgmMap) {
    this.mapRef = $event;
  }

  onOverlayComplete($overlayEvent: any) {
    this.drawingMode = this.drawingMode === null ? '' : null;
    if ($overlayEvent.type === OverlayType.POLYGONE) {
      const newPolygon = $overlayEvent.overlay.getPath()
        .getArray()
        .map((latLng: LatLng) => ({ lat: latLng.lat(), lng: latLng.lng() }))

      // start and end point should be same for valid geojson
      const startPoint = newPolygon[0];
      newPolygon.push(startPoint);
      $overlayEvent.overlay.setMap(null);
      this.polygons = [...this.polygons, newPolygon];
    }
  }

  onClickPolygon(index: number) {
    this.activePolygonIndex = index;
  }

  onEditPolygon(index: number) {
    const allPolygons = this.polygonRefs.toArray();
    allPolygons[index].getPath()
      .then((path: Array<LatLng>) => {
        this.polygons[index] = path.map((latLng: LatLng) => ({
          lat: latLng.lat(),
          lng: latLng.lng()
        }))
      })
  }

  onDeleteDrawing() {
    this.polygons = this.polygons.filter((polygon, index) => index !== this.activePolygonIndex)
  }

  // #region user permissions

  getUserPermissions() {
    this.checkOrderPermissions();
    this.checkSalesPermissions();
  }
  // todo change modules error
  checkOrderPermissions() {
    this.user.permissions.forEach((permission: PermissionDto) => {
      if (permission.name === 'orders') {
        permission.privileges.map((ele: string) => {
          switch (ele) {
            case "add":
              this.order_add = true;
              break;
            case "all":
              this.order_all = true;
              break;
            case "update":
              this.order_update = true;
              break;
            case "delete":
              this.order_delete = true;
              break;
          }
        });
      }
    });
  }

  checkSalesPermissions() {
    this.user.permissions.forEach((permission: PermissionDto) => {
      if (permission.name === 'sales') {
        permission.privileges.map((ele: string) => {
          switch (ele) {
            case "add":
              this.sales_add = true;
              break;
            case "all":
              this.sales_all = true;
              break;
            case "update":
              this.sales_update = true;
              break;
            case "delete":
              this.sales_delete = true;
              break;
          }
        });
      }
    });
  }

  // #endregion

  // #region ngOnInit

  ngOnInit() {
    this.connectToSocket()
    console.log({ asd: this.trackingPolygons })
    // this.polygons = this.trackingPolygons;
    // console.log('map polygons', this.polygons, 'ggg')
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.paramDate = queryParams.date;
      this.pickUpTodayDate();
    });

    this.clientLocationOnMap();

    if (this.addOrderMode) {
      this.getOrders();
    }
    else if (
      !this.orderDetailsMode &&
      !this.clientDetailsMode &&
      !this.clientDetailsPageMode &&
      !this.addOrderMapMode &&
      !this.paramDate &&
      !this.techDetailsMode
    ) {
      this.orderDateChanged(new Date());
      if (document.getElementById("filterStartDate") as HTMLInputElement) {
        (document.getElementById("filterStartDate") as HTMLInputElement).value = this.datePipe.transform(new Date(), "MM/dd/yyyy");
      }
    }
    this.listenOrdersUpdates();

    // load controls
    this.loadControls();
    this.orderDate = this.datePipe.transform(new Date(), "yyyy-MM-dd")
    this.getCurrentDate();
    console.log('destination => ', this.destination);
  }

  // #endregion

  // #region load controls

  connectToSocket() {
    this.socket = socket.io('http://46.101.249.171:3000/technician', {

      auth: {
        token: this.securityService.retrieveToken(),
        type: "web",
        techId: this.technicianId,
      },
      transports: ['websocket']
    });

    this.socket.on('connect', () => {
      console.log('Connected to server');
    });

    this.socket.on("connect_error", (error) => console.log(error));
    console.log(this.securityService.retrieveToken())
    this.socket.emit("web-want-tech-track", { techId: this.technicianId });
    console.log(this.technicianId)
    // Listen for a 'message' event from the server
    this.socket.on('web-tech-track-data', (data) => {
      console.log('Received message:', data);
      if (data) {
        this.array.push({
          lat: data.lat,
          lng: data.long
        })
        this.polygonsPositions.push(this.array)
        this.polygons = this.polygonsPositions
        console.log(this.polygons, "draw")
      }

      // this.polygons = data.map((d) => {
      //   return {
      //     lat: d.lat, lng: d.long
      //   }
      // })
      // console.log(this.polygons)
      // this.polygons = this.trackingPolygons;
      // console.log('map polygons', this.polygons, 'ggg')
    });

    // Send a 'chat message' event to the server
    this.socket.emit('chat message', 'Hello, world!');

  }

  loadControls() {
    this.getCities();
  }

  getCities() {
    this.sharedService.getCities().subscribe((response: ResponseDto) => {
      this.cities = response.data.cities;
      this.citiesLating = response.data.cities;
    });
  }

  getCompanies() {
    this.clientCompanyService.getAllClientCompanies({}).subscribe((response: ResponseDto) => {
      this.companies = response.data.companies;
      this.companiesLating = response.data.companies;
    });
  }

  getContracts() {
    let params = { has_waiting_orders: 'true' }
    this.contracts.getAllContracts(params).subscribe((response: ResponseDto) => {
      this.contractsOrders = response.data.companies;
      this.contractsOrdersLating = response.data.companies;
    });
  }

  // search by multiple technicians
  getTechnicians() {
    let params = { search: 'true', holidays: false, order_date: this.orderDate }
    this.technicianService.getTechnicians(params).subscribe((response: ResponseDto) => {
      this.listOfTechnicians = response.data.technicians;
    });
  }

  // #endregion

  // #region main actions

  searchByTechnicians() {
    this.matSelect.close();
    this.filteredTechnicians = this.convertSelectedTechniciansToStr();
    this.getOrders();
  }

  convertSelectedTechniciansToStr(): string {
    let technicianIds: string = '';
    this.selectedTechnicians.map((technicianId: number) => { technicianIds += `${technicianId},`; });
    // remove last comma
    technicianIds = technicianIds.slice(0, -1);
    return technicianIds
  }



  selectCity(cityID) {
    this.city_id = cityID;
    if (cityID == "") { this.getCities(); }
    else {
      this.cities.filter((city) => {
        if (city.id === cityID) {
          this.citiesLating = [];
          this.citiesLating.push(city);
        }
      });
    }
    this.getOrders();
  }

  selectCompany(companyId) {
    if (companyId == "") {
      this.clientCompanyService.getClientCompanyById(companyId);
    } else {
      this.companies.filter((company) => {
        if (company.id === companyId) {
          this.companiesLating = [];
          this.companiesLating.push(company);
        }
      });
    }
    this.getOrders();
  }

  getContractsOrders(companyId) {
    if (companyId == "") {
      this.clientCompanyService.getClientCompanyById(companyId);
    } else {
      this.companies.filter((company) => {
        if (company.id === companyId) {
          this.companiesLating = [];
          this.companiesLating.push(company);
        }
      });
    }
    this.getOrders();
  }

  displayOptionsFunction(state) {
    if (state !== null && state !== undefined) { return state.name; }
  }

  xResetInputs(key) {
    (document.getElementById(key) as HTMLInputElement).value = "";
    this.cityForm.controls[key].patchValue("");
  }

  getCurrentDate() {
    if (this.paramDate) { return this.datePipe.transform(this.orderDate, "yyyy-MM-dd"); }
    else { return this.orderDate; }
  }

  clientLocationOnMap() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams.clientDetailsMode) {
        this.clientDetailsMode = true;
        this.mapLatAndLong = { lng: +queryParams.long, lat: +queryParams.lat };
      }
    });
  }

  listenOrdersUpdates() {
    this.websocketService.websocketEventSubject$.subscribe(() => { this.getOrders(); });
  }

  getOrders() {
    let today = this.datePipe.transform(new Date(), "yyyy-MM-dd")
    if (!this.clientDetailsPageMode) {
      const params: any = {
        from_date: this.orderDate ? this.orderDate : today,
        to_date: this.orderDate ? this.orderDate : today,
        technician_ids: this.filteredTechnicians ? this.filteredTechnicians : '',
        city_id: this.city_id ? this.city_id : '',
      }
      this.orderService.getOrdersByTechnicians(params).subscribe((response: ResponseDto) => {
        // reset params variables
        this.orders = [];
        this.technicians = response.data.orders;
        response.data.orders.forEach((technicianOrder: OrderDto) => {
          this.structureStartAndEndTime(technicianOrder);
          this.orders.push(technicianOrder);
        });
        this.ordersLoaded = true;
      });
    }
  }

  structureStartAndEndTime(order: any) {
    if (order.start && order.end) {
      let timeStartArray = (order.start).split(':');
      order.start = { hour: Number(timeStartArray[0]), minute: Number(timeStartArray[1]) };
      let timeEndArray = (order.end).split(':');
      order.end = { hour: Number(timeEndArray[0]), minute: Number(timeEndArray[1]) };
    }
  }

  startTimeChanged(time) {
    this.timeFilterForm.patchValue({ start: time });
  }

  endTimeChanged(time) {
    this.timeFilterForm.patchValue({ end: time });
  }

  orderDateChanged(event) {
    let orderDate = this.datePipe.transform(event.value, "yyyy-MM-dd")
    this.orderDate = orderDate
    this.getOrders();
  }

  onMouseOver(infoWindow, gm) {
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  onMouseOut(infoWindow, gm) {
    gm.lastOpen = infoWindow;
    infoWindow.close();
  }

  openRecommendationForm() {
    this.showPopup = true;
    this.mapFilter = false;
    if (this.addOrderMode) {
      this.pickUpTodayDate();
    }
  }

  closeSuggestTechnicians() {
    this.searchingLoaderCog = false;
    this.showPopup = false;
    this.mapFilter = true;
    this.pickUpTodayDate();
  }

  pickUpTodayDate() {
    setTimeout(() => {
      if (
        !this.orderDetailsMode &&
        !this.clientDetailsMode &&
        !this.clientDetailsPageMode &&
        !this.addOrderMapMode &&
        !this.techDetailsMode
      ) {
        if (this.showPopup) {
          (document.getElementById(
            "aiDate"
          ) as HTMLInputElement).value = this.orderDate;
        } else {
          if (document.getElementById("filterStartDate") as HTMLInputElement) {
            (document.getElementById(
              "filterStartDate"
            ) as HTMLInputElement).value = this.orderDate;
          }
        }
      }
    }, 100);
  }


  markerDragEnd($event) {
    this.clickedNewClientOrgin.emit({ lat: $event.coords.lat, lng: $event.coords.lng, });
  }

  orderDetails(infoWindow, gm) {
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  resetTechnicians() {
    this.selectedTechnicians = []; //remove ids from list
    this.matSelect.close();
    (document.getElementById('technician') as HTMLInputElement).value = "";
    this.filterForm.controls['technician'].patchValue("");
    this.filteredTechnicians = '';
    this.getOrders();
  }

  waitingOrders(event) {
    this.isWaitingOrder = event.checked
    if (this.isWaitingOrder == true) {
      let params = { has_contract: "false" };
      this.orderService.getWaitingOrders(params).subscribe((response: any) => {
        this.waitingOrdersArray = response.data.orders;
      });
    }
    else { this.waitingOrdersArray = []; }
  }

  waitingCompanyOrders(event) {
    this.isWaitingCompany = event.checked
    if (this.isWaitingCompany == true) {
      let param = { has_contract: "true" };
      this.orderService.getWaitingOrders(param).subscribe((response: any) => {
        this.waitingCompanyOrdersArray = response.data.orders;
      });
      this.showContractsOrders = true;
      this.getContracts();
    } else {
      this.showContractsOrders = false;
      this.waitingCompanyOrdersArray = [];
    }
  }

  // #endregion

}
