// angular module
import { Component, OnInit } from "@angular/core";
import { trigger, animate, transition, style } from "@angular/animations";

// animation
import { fade } from "../../shared_animations/fade";

// models
import { PermissionDto } from "../../models/permission.model";

// services
import { SidebarTriggerService } from "./../../../shared/services/sidebar-trigger.service";
import { SecurityService } from "../../services/security.service";
import { UserDto } from "../../models/user.model";
import { Router } from "@angular/router";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
  animations: [
    trigger("fade", [
      transition(":enter", [
        style({ transform: "translateX(5%)", opacity: 0 }),
        animate("300ms", style({ transform: "translateX(0)", opacity: 1 })),
      ]),
      transition(":leave", [
        style({ transform: "translateX(0)", opacity: 1 }),
        animate("300ms", style({ transform: "translateX(5%)", opacity: 0 })),
      ]),
    ]),
    fade,
  ],
})
export class SideMenuComponent implements OnInit {

  // #region declare variables

  showSideMenu: boolean = false;
  overLayShow: boolean = false;
  order_add: boolean = false;
  order_all: boolean = false;
  client_add: boolean = false;
  client_all: boolean = false;
  technician_add: boolean = false;
  technician_all: boolean = false;
  offer_add: boolean = false;
  offer_all: boolean = false;
  resource_add: boolean = false;
  resources_all: boolean = false;
  receipt_add: boolean = false;
  receipt_all: boolean = false;
  sales_add: boolean = false;
  sales_all: boolean = false;
  service_add: boolean = false;
  service_all: boolean = false;
  orders: any = [];
  sales: any = [];
  clients: any = [];
  technicians: any = [];
  offers: any = [];
  resoureces: any = [];
  services: any = [];
  receipts: any = [];
  user: UserDto;
  windowWidth = window.innerWidth;
  receipt: any = "";
  grantAll: boolean = false;
  superAdmin: boolean;

  // #endregion

  // #region constructor

  constructor(
    private sidebarTriggerService: SidebarTriggerService,
    private securityService: SecurityService,
    private route: Router
  ) {
    // init variables
    this.user = new UserDto;
    this.superAdmin = false;
    // manage users
    this.manageUsers();
  }

  // #endregion

  // #region manage users

  manageUsers() {
    this.user = this.securityService.retrieveUser();
    if (this.user) {
      // redirect owner
      // if (this.user.privilege == 'owner') { this.route.navigate(['/home']); }
      if (this.user.privilege === 'super-admin'){
        this.superAdmin = true;
      }
      // grant all permissions
      if (this.user.privilege === 'super-admin'|| this.user.privilege === 'owner') {
        this.grantAll = true;
      }
      // check permissions for any logged in user other than super-admin
      else {
        this.checkPermissions(this.user)
      }
    }
  }


  // #endregion

  // #region check permissions

  checkPermissions(user: any) {
    for (let permission of user.permissions) {
      // orders permissions
      if (permission.name === 'orders') {
        this.checkOrdersPermission(permission);
      }
      if (permission.name === 'waiting_orders') {
        this.checkOrdersPermission(permission);
      }
      // client permissions
      if (permission.name === 'clients') {
        this.checkClientsPermissions(permission);
      }

      // technicians permissions
      if (permission.name === 'technicians') {
        this.checkTechniciansPermission(permission);
      }

      // offers permissions
      if (permission.name === 'offers') {
        this.checkOffersPermission(permission);
      }

      // resources permissions
      if (permission.name === 'resources') {
        this.checkResourcesPermission(permission);
      }

      // receipts permissions
      if (permission.name === 'receipts') {
        this.checkReceiptsPermission(permission);
      }

      // services permissions
      if (permission.name === 'services') {
        this.checkServicesPermission(permission);
      }
    }
  }

  checkOrdersPermission(permission: PermissionDto) {
    this.orders = permission.privileges;
    if (this.orders) {
      this.orders.map(ele => {
        switch (ele) {
          case 'create':
            this.order_add = true;
            break;
          case 'all':
            this.order_all = true;
            break;
            // case 'create_waiting':
            // this.order_all = true;
            // break;
        }
      });
    }
  }

  checkClientsPermissions(permission: PermissionDto) {
    this.clients = permission.privileges;
    if (this.clients) {
      this.clients.map(ele => {
        switch (ele) {
          case 'create':
            this.client_add = true;
            break;
          case 'all':
            this.client_all = true;
            break;
        }
      });
    }
  }

  checkTechniciansPermission(permission: PermissionDto) {
    this.technicians = permission.privileges;
    if (this.technicians) {
      this.technicians.map(ele => {
        switch (ele) {
          case 'create':
            this.technician_add = true;
            break;
          case 'all':
            this.technician_all = true;
            break;
        }
      });
    }
  }

  checkOffersPermission(permission: PermissionDto) {
    this.offers = permission.privileges;
    if (this.offers) {
      this.offers.map(ele => {
        switch (ele) {
          case 'create':
            this.offer_add = true;
            break;
          case 'all':
            this.offer_all = true;
            break;
        }
      });
    }
  }

  checkResourcesPermission(permission: PermissionDto) {
    this.resoureces = permission.privileges;
    if (this.resoureces) {
      this.resoureces.map(ele => {
        switch (ele) {
          case 'create':
            this.resource_add = true;
            break;
          case 'all':
            this.resources_all = true;
            break;
        }
      });
    }
  }

  checkReceiptsPermission(permission: PermissionDto) {
    this.receipts = permission.privileges;
    if (this.receipts) {
      this.receipts.map(ele => {
        switch (ele) {
          case 'create':
            this.receipt_add = true;
            break;
          case 'all':
            this.receipt_all = true;
            break;
        }
      });
    }
  }

  checkServicesPermission(permission: PermissionDto) {
    this.services = permission.privileges;
    if (this.services) {
      this.services.map(ele => {
        switch (ele) {
          case 'all':
            this.service_all = true;
            break;
        }
      });
    }
  }

  // #endregion

  // #region ngOnInit 

  ngOnInit() {
    /* ------------- Check Responsive ------------------- */
    if (this.windowWidth > 991) {
      this.showSideMenu = true;
    } else {
      this.showSideMenu = false;
    }
    this.sidebarTriggerService.sidebarTriggerObservable.subscribe((params) => {
      params === "block"
        ? (this.showSideMenu = true)
        : (this.showSideMenu = false);
    });
    this.sidebarTriggerService.overlayTriggerObservable.subscribe((params) => {
      params === "block"
        ? (this.overLayShow = true)
        : (this.overLayShow = false);
    });

  }

  // #endregion

  // #region main actions

  /* ------------------- Overlay Sidebar ------------------------ */
  sidebarOverlayTrigger() {
    this.sidebarTriggerService.toggelSidemenu();
    this.sidebarTriggerService.toggelOverlay();
  }
  /* --------------- Carousal ---------------- */
  toggle_drop_List(e) {
    
    const element = document.getElementsByClassName("drop_menu");
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < element.length; i++) {

      if (element[i].id === e) {

        document.getElementById(e).classList.toggle("active_list");
      } else {
        element[i].classList.remove("active_list");
      }
    }
  }

  // #endregion
}
