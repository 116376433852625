// angular modules
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {

  // #region private content

  private localStorage: any;

  // #endregion

  // #region constructor

  constructor() {
    this.localStorage = window.localStorage;

  }

  // #endregion

  // #region main actions

  // retrieve data from local storage
  retrieve(key: string) {
    const item = this.localStorage.getItem(key);

    return item !== null && item !== "undefined" ? JSON.parse(this.localStorage.getItem(key)) : null
  }

  actCurrentUserAsSuperAdmin(companyslug) {
    let currenLoggedInUser = this.retrieve('user');
    if(currenLoggedInUser) {
      console.log(currenLoggedInUser);
      currenLoggedInUser.companySlug = companyslug.toString();
      this.store('user', currenLoggedInUser);
    }
  }

  // store data in local storage
  store(key: string, value: any) {
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  // remove one item from local storage
  remove(key: string) {
    this.localStorage.removeItem(key);
  }

  // remove all items from local storage
  removeAll() {
    this.localStorage.clear();
  }
  
  storePermissions(key,value){

    console.log(key,value,"key valuee")
    this.localStorage.setItem(key,value)
  }
  // #endregion
}
