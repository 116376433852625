<!-- {{ message | async | json }} -->
<!-- <div> -->
<main [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
  <!-- style tag is added by amira where height is calculated in app.component.ts [style]="documentHeight" -->
  <div class="loading-wrapper" *ngIf="loaderService?.loading">
    <app-loader></app-loader>
  </div>
  <router-outlet #o="outlet"></router-outlet>
</main>
<!-- </div> -->
