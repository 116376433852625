// angular modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseDto } from 'src/app/shared/models/response.model';
import { SecurityService } from 'src/app/shared/services/security.service';
import { ClientCompanyDto, CreateBranchDto } from './client-company.model';

@Injectable({
  providedIn: 'root'
})

export class ClientCompanyService {

  // #region constructor

  constructor(
    private securityService: SecurityService,
    private http: HttpClient
  ) { }

  // #endregion

  // #region company actions

  getAllClientCompanies(params:any={}) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/client-companies`;
    return this.http.get<ResponseDto>(url, { params: params })
  }

  createClientCompany(body: ClientCompanyDto) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/client-companies`;
    return this.http.post(url, body)
  }

  checkUniqueness(body:any){
    const url = `admin/company/${this.securityService.getUserCompanySlug}/moderators/check-unique`;
    return this.http.post<ResponseDto>(url,body)
  }
  // used in show company details, list branches, and show branches
  getClientCompanyById(id: number, params: any = {}) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/client-companies/${id}`;
    return this.http.get<ResponseDto>(url, { params: params });
  }

  updateClientCompany(id: number, body: any) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/client-companies/${id}`;
    return this.http.put<ResponseDto>(url, body);
  }

  // #endregion

  // #region branches actions

  updateCompanyBranch(branchId: number, body: any) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/client-companies/${branchId}`;
    return this.http.put<ResponseDto>(url, body);
  }

  createCompanyBranch(body: CreateBranchDto) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/client-companies`;
    return this.http.post<ResponseDto>(url, body);
  }

  validateMap(body:any){
  const url =  `admin/company/${this.securityService.getUserCompanySlug}/clients/validate-map`;
  return this.http.post<ResponseDto>(url,body);
  }
  // #endregion

}
