// angular modules
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// services
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})

export class SecurityService {

  // #region private content

  private userStorageKey: string = "user"

  // #endregion

  // #region public properties

  get jwtToken() { return this.retrieveToken(); }

  get getUserCompanySlug() { return this.user.companySlug; }

  get user() { return this.retrieveUser(); }



  // #endregion

  // #region constructor

  constructor(
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
  ) {

  }

  // #endregion

  // #region actions

  logout() {
    this.removeUser();
    this.router.navigate(['/access/login'])
  }

  // #endregion

  // #region retrieve local storage actions

  retrieveToken() {
    const user = this.localStorageService.retrieve(this.userStorageKey);
    return typeof user !== "undefined" || user !== null ? user.token : null;
  }

  retrieveUser() {
    const user = this.localStorageService.retrieve(this.userStorageKey);
    return typeof user && user !== "undefined" ? user : null;
  }

  storeToken(value: string) {
    this.localStorageService.store(this.userStorageKey, value);
  }

  removeUser() {
    this.localStorageService.remove(this.userStorageKey);
  }

  retrieveCompanySlug() {
    const user = this.localStorageService.retrieve(this.userStorageKey);
    return typeof user !== "undefined" ? user.companySlug : null;
  }

  // #endregion
}
