import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Subject, ReplaySubject } from 'rxjs';
import { Howl, Howler } from 'howler';
import { AlarmService } from './alarm.service';
import { SecurityService } from './security.service';
import { LocalStorageService } from './local-storage.service';
@Injectable()
export class MessagingService {
  /* ----------------------- Variables ------------------------- */
  messagesArray = [];
  currentMessage = new BehaviorSubject(this.messagesArray);
  orderUpdatedNotification = new ReplaySubject();
  tokenSubject = new Subject();
  /* ---------------- Constructor ------------------------ */
  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private alarmService: AlarmService,
    private securityService: SecurityService,
    private localStorage: LocalStorageService
  ) {
    // FIXME: insted of subscribe i used then
    // this.angularFireMessaging.messaging.then(messaging => {
    //   messaging.onMessage = messaging.onMessage.bind(messaging);
    //   messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
    // });
    // FIXME: messaging: any should be type AngularFireMessaging
    this.angularFireMessaging.messages.subscribe((messaging: any) => {
 

      messaging.onMessage = messaging.onMessage.bind(messaging);
      messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
    });
  }
  /* ------------------ Update Token in Firebase ------------------ */
  updateToken(userId, token) {
    this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
      const data = {};
      //fixme check hat user id
      data[userId] = token;
      this.angularFireDB.object('fcmTokens/').update(data);
    });
  }

  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        if (token) {



          localStorage.setItem('notificationToken', JSON.stringify(token));
          this.tokenSubject.next(token);
          this.updateToken(userId, token);
        }
        // else {
        //   let token = this.localStorage.retrieve('notificationToken')
        //   this.tokenSubject.next(token);
        //   this.updateToken(userId, token)
        // }
      },
      err => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }



  receiveMessage() {
    // fixme: check notification company matches current user company

    this.angularFireMessaging.messages.subscribe((payload: any) => {
      // if (payload.data.company_slug == this.securityService.getUserCompanySlug) {

      // if( payload.data.notification_type == "order_created") {

      //   this.alarmService.play()
      // }

      this.orderUpdatedNotification.next(payload);
      this.messagesArray.push(payload);
      this.currentMessage.next(this.messagesArray);
      // }
    });
  }
}
