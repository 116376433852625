import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseDto } from 'src/app/shared/models/response.model';
import { SecurityService } from '../../../shared/services/security.service';
import { ContractDto, CreateContractDto } from '../models/contract.model';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(
    private http: HttpClient,
    private securityService: SecurityService
  ) { }

  getAllContracts(params: any = {}) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/contracts`;
    return this.http.get<ResponseDto>(url, { params: params })
  }

  checkContractNumberValidity(body: any) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/contracts/check-number`;
    return this.http.post<ResponseDto>(url, body);

  }

  createContract(data: CreateContractDto) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/contracts`;
    return this.http.post<ResponseDto>(url, data);
  }

  getContract(id: number) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/contracts/${id}`;
    return this.http.get<ResponseDto>(url);
  }

  deletePdf(contract_id: number, media_id: number) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/contracts/${contract_id}/media/${media_id}`;
    return this.http.delete<ResponseDto>(url)
  }

  updateContractStatus(contract_id: number, body: any) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/contracts/${contract_id}/update-status`;
    return this.http.patch<ResponseDto>(url, body)
  }

  updateContract(contract_id: number, body: any) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/contracts/${contract_id}`;
    return this.http.put(url, body);
  }

  deleteContract(contract_id: number) {
    const url = `admin/company/${this.securityService.getUserCompanySlug}/contracts/${contract_id}`;
    return this.http.delete<ResponseDto>(url)
  }
}
