<div class="header">

  <div id="userNotification" (mouseenter)="showNotification = true" (mouseleave)="showNotification = false">
    <i class="fas fa-bell">
      <!-- <span class="tinyCounter" *ngIf="message.length > 0" [@fade]>{{
        message.length
      }}</span> -->
      <span class="tinyCounter number" *ngIf="listOfNotifications.length > 0" [@fade]>{{
        listOfNotifications.length
      }}</span>
    </i>
    <div [ngClass]="
        showNotification ? 'showNotificationPanel' : 'hideNotificationPanel'
      " id="notificationBodyContainer">
      <div id="notificationBody">
        <h3><i class="fas fa-bell"></i> الإشعارات</h3>
        <ul id="listBody">
          <!-- <li *ngIf="message.length === 0">لا يوجد إشعارات</li> -->
          <li *ngIf="listOfNotifications.length === 0">لا يوجد إشعارات</li>
          <!-- <li *ngFor="let messageItem of message" [routerLink]="['/orders/order-details']"
            [queryParams]="{ orderId: messageItem.data.id }">
            <ng-container *ngIf="!messageItem.data.body">{{ messageItem.data.title }}
            </ng-container>
            <ng-container *ngIf="messageItem.data.body">
              <h4>
                <img alt="" src="../../../../assets/img/icons/Rectangle 904.svg" />
                {{ messageItem.data.title }}
              </h4>
              <p>{{ messageItem.data.body }}</p>
              <span>{{ messageItem.created_at | date: "MM/dd/yyyy" }}</span>
            </ng-container>
          </li> -->
          <li *ngFor="let notification of listOfNotifications"
            [routerLink]="['/orders/order-details', notification.data.id ]">
            <ng-container *ngIf="!notification.data.order_status && notification.type=='order_created'">
              <h4>
                <img alt="" src="../../../../assets/img/icons/Rectangle 904.svg" />
                <span>{{ notification.type == 'order_created' ? 'تم إضافة طلب جديد' : ''}}</span>
              </h4>

              <p>رقم الطلب {{notification.data.order_number}}</p>
            </ng-container>
            <ng-container *ngIf="notification.data.order_status">
              <h4>
                <img alt="" src="../../../../assets/img/icons/Rectangle 904.svg" />
                {{ notification.data.order_status }}
              </h4>
              <p>{{ notification.data.order_status }} طلب رقم {{notification.data.order_number}} مقدم الخدمة
                {{notification.data.technician_name}}</p>
              <!-- <span>{{ notification.created_at | date: "MM/dd/yyyy" }}</span> -->
            </ng-container>

          </li>

          <li *ngIf="!emptyNotificationArray">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div id="userComments">
    <i class="fas fa-comments">
      <!-- <span class="counterComments" *ngIf="message.length > 0"></span> -->
    </i>
  </div>
  <div id="userExclamation">
    <i class="fas fa-exclamation-circle">
      <!-- <span class="counterExclamation" *ngIf="message.length > 0"></span> -->
    </i>
  </div>

  <div *ngIf="this.alarmService.unApprovedOrders" class="mobileOrders">
    <span>
      <i matTooltipPosition="below" matTooltip="يوجد اعمال غير مؤكدة ">
        <img src="../../../../assets/img/home_page/Bell.gif" alt="bell" />
      </i>
    </span>
  </div>
  <div id="newDateToday">
    <p class="number">
      {{
        today | date: " dd/MM/yyyy &nbsp; &nbsp;  h:mm:ss &nbsp; a ":" UTC +3 "
      }}
      &nbsp; &nbsp; (KSA Time)
    </p>
  </div>
  <!-- firebase -->
  <div class="alert-notification" id="alert-notification-{{ i }}"
    *ngFor="let notification of currentNotification; let i = index" [@fade]>
    <span class="notification-container">
      <span class="xClose">
        <i class="fas fa-times" (click)="closeNotifications(i)"></i>
      </span>
      <span class="bell">
        <i class="far fa-bell"> </i>
      </span>
      <h4 class="notiifation-tilte">
        {{ notification.data.notification_type != 'order_created' ? notification.notification.title : 'تم إضافة طلب' }}
      </h4>
      <img [src]="adminImage" alt="" />
      <h5>
        {{ notification.data.notification_type != 'order_created' ? notification.notification.body : 'تم إضافة طلب بنجاح' }}
      </h5>
      <a [routerLink]="['/orders/order-details', notification.data.order_id]">الذهاب الى طلب</a>
    </span>
  </div>
  <!-- websocket -->
  
  <div class="alert-notification" id="alert-notification-{{ i }}"
    *ngFor="let notification of listOfWebSocketNotification; let i = index" [@fade]>
    <span class="notification-container" >
      <span class="xClose">
        <i class="fas fa-times" (click)="closeWebsocketNotificationPopup(i)"></i>
      </span>
      <span class="bell">
        <i class="far fa-bell"> </i>
      </span>
      <h4 class="notiifation-tilte">
        {{ notification.type == 'created' ? 'تم إضافة طلب' : notification.type == 'updated' ? 'تم تحديث طلب' : 'تعديل صلاحيات' }}
      </h4>
      <img [src]="adminImage" alt="" />
      <h5>
        {{ notification.type == 'created' ? 'تم إضافة طلب بنجاح' : notification.type == 'permissionChanges' ?   'تم تعديل الصلاحيات': notification.order_status}}
      </h5>
   
      <a [routerLink]="['/orders/order-details', notification.id]" *ngIf="notification.type == 'created' || notification.type == 'status'">الذهاب الى طلب</a>
    </span>
  </div>



  <div class="user_menu" (mouseenter)="showLoggedUser = true" (mouseleave)="showLoggedUser = false">
    <span class="activeGreen"></span>
    <img [src]="adminImage" alt="" />
    <div *ngIf="showLoggedUser" [@popupAnimation] id="loggedUserBodyContainer">
      <div id="loggedUserBody">
        <div id="head">
          <div class="loggedUserBigImage">
            <img [src]="adminImage" alt="" />
          </div>
          <span>{{ adminTitle }}</span>
        </div>
        <div id="body">
          <ul>
            <li>
              <a style="color: black" routerLink="/password/change_password_input">
                <i class="fas fa-lock"></i> تغيير كلمة السر
              </a>
            </li>
            <li>
              <button type="button" (click)="logout()">
                <i class="fas fa-sign-out-alt"></i> تسجيل الخروج
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="layout_sidebar" (click)="sidebarTrigger()">
    <a class="sidebar_trigger">
      <i class="fa fa-bars"></i>
    </a>
  </div>
  <mat-progress-bar *ngIf="loading" [@fade] mode="indeterminate"></mat-progress-bar>
</div>
