import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseDto } from '../models/response.model';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  // #region constructor

  constructor(
    private http: HttpClient,
    private securityService: SecurityService
  ) { }

  // #endregion

  // #region main actions

  getNotifications() {
    const url = `company/${this.securityService.getUserCompanySlug}/notifications`;
    return this.http.get<ResponseDto>(url);
  }

  // #endregion
}
