// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD176wddiMced_8D-1A1Eller4BbbiFiKo",
    authDomain: "upkeep-80597.firebaseapp.com",
    databaseURL: "https://upkeep-80597-default-rtdb.firebaseio.com",
    projectId: "upkeep-80597",
    storageBucket: "upkeep-80597.appspot.com",
    messagingSenderId: "831178960997",
    appId: "1:831178960997:web:2051a45186659eb7ad168c",
    measurementId: "G-1SCH1CDN0Q"
  },
  baseUrl: "https://api.upkeep.com.sa/api/v1/",
  webSocket: {
    BROADCASTER: 'pusher',
    KEY: 11,
    CLUSTER: 'mt1',
    WSHOST: 'websocket.upkeep.com.sa',
    WSSPORT: 6001
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
