export const NotificationStatus = [
    { status: "not_approved", value: "غير مؤكد" },
    { status: "admin_approved", value: "مؤكد من الأدمن" },
    { status: "technician_approved", value: "مؤكد من مقدم الخدمة" },
    { status: "started", value: "تم البدء" },
    { status: "finished", value: "انتهت" },
    { status: "postponed", value: "تم التأجيل" },
    { status: "receipted", value: "تم إصدار فاتورة" },
    { status: "canceled", value: "تم الإلغاء" },
    { status: "ongoing", value: "في  الطريق" },
    { status: "suspended", value: "موقوف" },
    { status: "finishedWithoutReceipt", value: "انهاء الطلب بدون اصدار فاتورة" },

]