<!-- <div class="lds-spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>

</div>  -->
<div style="background-color: rgb(253, 217, 169);
margin-top:0;
position:fixed;
top:5px;
padding: 4px;
z-index: 99999999;
color:black;
border-radius: 7px;
">جاري التحميل...</div>